import React, { useState } from "react";
import { ChildrenProps } from "../models/componentModel";
import { CommonResponseSecondaryModel, forgotPasswordPayloadModel, forgotPasswordResponseModel, loginPayloadModel, userLogoutResponseDataModel, UserMeResponseDataModel, UserRegistrationPayloadModel } from "../models/axiosModels";
import { forgotPasswordPayloadDetails, intialUserDetails, loginPayloadDetails, UserRegistrationPayloadDetails } from "../Utils/Constant/UserDetailsConstant";
import agent from "../Services/api";

const GlobalUserContext = React.createContext<GlobalUserContextPayload | undefined>(
    undefined
);

export interface GlobalUserContextPayload {

    drawerOpen: boolean;
    setDrawerOpen: React.Dispatch<React.SetStateAction<boolean>>

    currentSelectedTab: any;
    setCurrentSelectedTab: React.Dispatch<React.SetStateAction<any>>

    isAdminNavbarVisible: boolean
    setIsAdminNavbarVisible: React.Dispatch<React.SetStateAction<boolean>>

    userRole: any;
    setUserRole: React.Dispatch<React.SetStateAction<any>>

    userLoggedInFlag: any;
    setUserLoggedInFlag: React.Dispatch<React.SetStateAction<any>>

    flagLoader: boolean
    setFlagLoader: React.Dispatch<React.SetStateAction<boolean>>

    signinDetails: loginPayloadModel;
    setSigninDetails: React.Dispatch<React.SetStateAction<loginPayloadModel>>
    signinAPIFunction: (payload: loginPayloadModel) => Promise<CommonResponseSecondaryModel<loginPayloadModel>>

    userProfileDetails: UserMeResponseDataModel;
    setUserProfileDetails: React.Dispatch<React.SetStateAction<UserMeResponseDataModel>>
    userDetails: () => Promise<CommonResponseSecondaryModel<UserMeResponseDataModel>>

    registerUserProfileDetails: UserRegistrationPayloadModel;
    setRegisterUserProfileDetails: React.Dispatch<React.SetStateAction<UserRegistrationPayloadModel>>
    userRegistrationDetails: (payload: UserRegistrationPayloadModel) => Promise<CommonResponseSecondaryModel<UserMeResponseDataModel>>

    forgotPasswordDetails: forgotPasswordPayloadModel;
    setForgotPasswordDetails: React.Dispatch<React.SetStateAction<forgotPasswordPayloadModel>>
    forgotPassword: (payload: forgotPasswordPayloadModel) => Promise<forgotPasswordResponseModel>

    logout: () => Promise<CommonResponseSecondaryModel<userLogoutResponseDataModel>>
}



const GlobalUserProvider = ({ children }: ChildrenProps) => {

    // FLAG CONST 
    const [flagLoader, setFlagLoader] = useState(false)
    const [drawerOpen, setDrawerOpen] = useState<boolean>(false)
    const [userLoggedInFlag, setUserLoggedInFlag] = useState<boolean>(true)
    const [currentSelectedTab, setCurrentSelectedTab] = useState(null)
    const [isAdminNavbarVisible, setIsAdminNavbarVisible] = useState(false)
    const [userRole, setUserRole] = useState("Admin")


    // SIGN IN 
    const [signinDetails, setSigninDetails] = useState<loginPayloadModel>(loginPayloadDetails)
    const [userProfileDetails, setUserProfileDetails] = useState<UserMeResponseDataModel>(intialUserDetails)
    const [registerUserProfileDetails, setRegisterUserProfileDetails] = useState<UserRegistrationPayloadModel>(UserRegistrationPayloadDetails)
    const [forgotPasswordDetails, setForgotPasswordDetails] = useState<forgotPasswordPayloadModel>(forgotPasswordPayloadDetails)

    const signinAPIFunction = async (payload: loginPayloadModel) => {
        const response = await agent.signInAPI.post(payload);
        return response;
    };

    const userDetails = async () => {
        const response = await agent.userDetailAPI.get();
        return response;
    };

    const userRegistrationDetails = async (payload: UserRegistrationPayloadModel) => {
        const response = await agent.userRegistrationAPI.post(payload);
        return response;
    };

    const logout = async () => {
        const response = await agent.logoutAPI.get();
        return response;
    };

    const forgotPassword = async (payload: forgotPasswordPayloadModel) => {
        const response = await agent.forgotPasswordAPI.post(payload);
        return response
    }

    const payload = {
        drawerOpen,
        setDrawerOpen,
        currentSelectedTab,
        setCurrentSelectedTab,
        isAdminNavbarVisible,
        setIsAdminNavbarVisible,
        userRole,
        setUserRole,
        userLoggedInFlag,
        setUserLoggedInFlag,
        flagLoader,
        setFlagLoader,

        signinDetails,
        setSigninDetails,
        signinAPIFunction,

        userProfileDetails,
        setUserProfileDetails,
        userDetails,

        userRegistrationDetails,
        registerUserProfileDetails,
        setRegisterUserProfileDetails,

        logout,

        forgotPassword,
        forgotPasswordDetails,
        setForgotPasswordDetails
    };

    return (
        <GlobalUserContext.Provider value={payload}>{children}</GlobalUserContext.Provider>
    );
};

export { GlobalUserContext, GlobalUserProvider };
