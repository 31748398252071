import React, { useEffect } from 'react'
import globalStyles from "../../../Styles/global.module.scss"
import styles from "../../../Styles/global.module.scss"

import Grid from '@mui/material/Grid2';
import AppNavbar from '../../../Components/AppNavbar/AppNavbar';
import DashboardNavbar from '../DashboardNavbar/DashboardNavbar';
import { Typography } from '@mui/material';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from '@mui/material';
import GlobalTextfield from '../../../Utils/Layout/GlobalTextfield/GlobalTextfield';
import { annualFeesPlanAssets, data, planAssumptionRows } from '../../../Utils/Constant/Constant';
import ComplianceDocument from '../../../Components/ComplianceDocument/ComplianceDocument';
import { useNavigate } from 'react-router-dom';
import GlobalButton from '../../../Utils/Layout/GlobalButton/GlobalButton';
import Icons from '../../../Utils/Layout/Icons/Icons';
import CanvasJSReact from '../../../Lib/canvasjs-stockchart-1.10.16/canvasjs.stock.react'

const CanvasJSChart = CanvasJSReact.CanvasJSChart;

const DashboardPlan401k = () => {

    const navigate = useNavigate()

    const labels = ["1 Year", "5 Years", "10 Years", "15 Years", "20 Years"];
    const acrylicData = [1, 3, 6, 10, 15]; // Acrylic portfolio data
    const highlandData = [0.8, 2.5, 5.5, 9, 13]; // Highland portfolio data

    const handleClick = () => {
        navigate('/')
    }

    const chartOptions = {
        backgroundColor: "white", // Chart background color
        title: {
            text: "IMPACT OF FEES ON BALANCE",
            fontColor: "gray",
            fontFamily: "Arial",
            fontSize: 18,
            fontWeight: "bold",
        },
        axisY: {
            title: "PORTFOLIO BALANCE (mm)",
            titleFontColor: "gray",
            labelFontColor: "gray",
            gridColor: "rgba(200, 200, 200, 0.1)",
            prefix: "$",
        },
        axisX: {
            labelFontColor: "gray",
            gridColor: "rgba(200, 200, 200, 0.1)",
        },
        data: [
            {
                type: "line",
                name: "Acrylic",
                showInLegend: true,
                lineColor: "blue",
                dataPoints: labels.map((label, index) => ({
                    label,
                    y: acrylicData[index],
                })),
            },
            {
                type: "line",
                name: "Highland Smiles, PLLC",
                showInLegend: true,
                lineColor: "red",
                dataPoints: labels.map((label, index) => ({
                    label,
                    y: highlandData[index],
                })),
            },
        ],
        legend: {
            cursor: "pointer",
            fontSize: 14,
            fontColor: "gray",
            horizontalAlign: "top",
        },
    };


    return (
        <>
            <AppNavbar navbarHeadingName={"Dashboard"} />
            <DashboardNavbar
                onClick={handleClick}
                headName="401 (K) Plan Fee Comparison"
            >
                <Grid>
                    <GlobalButton
                        variant='outlined'
                        value='Download'
                        size='medium'
                        startIcon={<Icons iconName="downloadIcon" iconWidth="14px" iconHeight="14px" />}
                        className={`${globalStyles.gblOutlineBtn}`}
                    />
                </Grid>
                <Grid>
                    <GlobalButton
                        variant='outlined'
                        value='Calculate'
                        size='medium'
                        className={`${globalStyles.gblContainedBtn}`}
                    />
                </Grid>
            </DashboardNavbar>
            <Grid container>
                <Grid size={{ xs: 12 }}>
                    <Grid container>
                        <Grid size={{ xs: 12 }}>
                            <TableContainer>
                                <Table size='small' className={`${globalStyles.gblTableStyles}`}>
                                    <TableHead className={`${globalStyles.gblTableHeadStyles}`}>
                                        <TableRow >
                                            <TableCell colSpan={3} className={`${globalStyles.gblTableHeadCell}`} sx={{ borderRadius: "5px" }}>
                                                <Typography
                                                    component={'span'}
                                                    className={`${globalStyles.globalTextV14}`}
                                                >
                                                    PLAN ASSUMPTIONS
                                                </Typography>
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {planAssumptionRows?.map((row, index) => (
                                            <TableRow key={index} className={`${globalStyles.gblTableBodyRow}`}>
                                                <TableCell
                                                    className={`${globalStyles.gblTableCellText} ${globalStyles.gblLeftRadius}`}
                                                >
                                                    {row.label}
                                                </TableCell>
                                                <TableCell
                                                    className={`${globalStyles.gblTableCellText} ${globalStyles.gblRightRadius}`}
                                                    align='right'
                                                >
                                                    <GlobalTextfield
                                                        variant='outlined'
                                                        size='small'
                                                        value={row.acrylic}
                                                        stylingClassName={`${globalStyles.gblTableField}`}
                                                    />
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>

                        <Grid size={{ xs: 12 }}>
                            <Grid container>
                                <Grid size={{ xs: 12 }} sx={{ margin: "20px 0px 5px" }}>
                                    <Typography component={'span'}
                                        className={`${globalStyles.globalTextV18} ${globalStyles.gblCWhite}`}
                                    >
                                        Annual fees paid directly by the Business
                                    </Typography>
                                </Grid>
                                <Grid size={{ xs: 12 }}>
                                    <TableContainer>
                                        <Table size='small' className={`${globalStyles.gblTableStyles}`}>
                                            <TableHead className={`${globalStyles.gblTableHeadStyles}`}>
                                                <TableRow sx={{ borderRadius: "5px" }}>
                                                    <TableCell className={`${globalStyles.gblTableHeadCell} ${globalStyles.gblLeftRadius}`}>
                                                        <Typography
                                                            component={'span'}
                                                            className={`${globalStyles.globalTextV14}`}
                                                        >
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell className={`${globalStyles.gblTableHeadCell} `}>
                                                        <Typography
                                                            component={'span'}
                                                            className={`${globalStyles.globalTextV14}`}
                                                        >
                                                            ACRYLIC
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell className={`${globalStyles.gblTableHeadCell} ${globalStyles.gblRightRadius}`} align='right'>
                                                        <Typography
                                                            component={'span'}
                                                            className={`${globalStyles.globalTextV14}`}
                                                        >
                                                            HIGHLAND SMILES, PLLC
                                                        </Typography>
                                                    </TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {planAssumptionRows?.map((row, index) => (
                                                    <TableRow key={index} className={`${globalStyles.gblTableBodyRow}`}>
                                                        <TableCell
                                                            className={`${globalStyles.gblTableCellText} ${globalStyles.gblLeftRadius}`}
                                                        >
                                                            {row.label}
                                                        </TableCell>
                                                        <TableCell
                                                            className={`${globalStyles.gblTableCellText}`}
                                                            align='left'
                                                        >
                                                            {row.acrylic}
                                                        </TableCell>
                                                        <TableCell
                                                            className={`${globalStyles.gblTableCellText} ${globalStyles.gblRightRadius}`}
                                                            align='right'
                                                        >
                                                            <GlobalTextfield
                                                                variant='outlined'
                                                                size='small'
                                                                value={row.acrylic}
                                                                stylingClassName={`${globalStyles.gblTableField}`}
                                                            />
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid size={{ xs: 12 }}>
                            <Grid container>
                                <Grid size={{ xs: 12 }} sx={{ margin: "20px 0px 5px" }}>
                                    <Typography component={'span'}
                                        className={`${globalStyles.globalTextV18} ${globalStyles.gblCWhite}`}
                                    >
                                        Annual fees paid from plan assets
                                    </Typography>
                                </Grid>
                                <Grid size={{ xs: 12 }}>
                                    <TableContainer>
                                        <Table size='small' className={`${globalStyles.gblTableStyles}`}>
                                            <TableHead className={`${globalStyles.gblTableHeadStyles}`}>
                                                <TableRow sx={{ borderRadius: "5px" }}>
                                                    <TableCell className={`${globalStyles.gblTableHeadCell} ${globalStyles.gblLeftRadius}`}>
                                                        <Typography
                                                            component={'span'}
                                                            className={`${globalStyles.globalTextV14}`}
                                                        >
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell className={`${globalStyles.gblTableHeadCell} `}>
                                                        <Typography
                                                            component={'span'}
                                                            className={`${globalStyles.globalTextV14}`}
                                                        >
                                                            ACRYLIC
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell className={`${globalStyles.gblTableHeadCell} ${globalStyles.gblRightRadius}`} align='right'>
                                                        <Typography
                                                            component={'span'}
                                                            className={`${globalStyles.globalTextV14}`}
                                                        >
                                                            HIGHLAND SMILES, PLLC
                                                        </Typography>
                                                    </TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {annualFeesPlanAssets?.map((row, index) => (
                                                    <TableRow key={index} className={`${globalStyles.gblTableBodyRow}`}>
                                                        <TableCell
                                                            className={`${globalStyles.gblTableCellText} ${globalStyles.gblLeftRadius}`}
                                                        >
                                                            {row.label}
                                                        </TableCell>
                                                        <TableCell
                                                            className={`${globalStyles.gblTableCellText}`}
                                                        >
                                                            {row.acrylic}
                                                        </TableCell>
                                                        <TableCell
                                                            className={`${globalStyles.gblTableCellText} ${globalStyles.gblRightRadius}`}
                                                            align='right'
                                                        >
                                                            <GlobalTextfield
                                                                variant='outlined'
                                                                size='small'
                                                                value={row.acrylic}
                                                                stylingClassName={`${globalStyles.gblTableField}`}
                                                            />
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid size={{ xs: 12 }}>
                            <Grid container>
                                <Grid size={{ xs: 12 }} >
                                    <Typography component={'span'} className={`${globalStyles.globalTextV18} ${globalStyles.gblCWhite}`}>
                                        Comparison Fee Report
                                    </Typography>
                                </Grid>
                                <Grid size={{ xs: 12 }}>
                                    <CanvasJSChart options={chartOptions} />
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid size={{ xs: 12 }}>
                            <Grid container>
                                <Grid size={{ xs: 12 }} sx={{ margin: "20px 0px 5px" }}>
                                    <Typography component={'span'}
                                        className={`${globalStyles.globalTextV18} ${globalStyles.gblCWhite}`}
                                    >
                                        Annual fees paid from plan assets
                                    </Typography>
                                </Grid>
                                <Grid size={{ xs: 12 }}>
                                    <TableContainer>
                                        <Table size='small' className={`${globalStyles.gblTableStyles}`}>
                                            <TableHead>
                                                <TableRow sx={{ borderRadius: "5px", background: "white" }}>
                                                    <TableCell className={`${globalStyles.gblTableHeadCell} ${globalStyles.gblLeftRadius}`}>
                                                        <Typography
                                                            component={'span'}
                                                            className={`${globalStyles.globalTextV14}`}
                                                        >
                                                            YEAR
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell className={`${globalStyles.gblTableHeadCell} `} colSpan={2} align='center'>
                                                        <Typography
                                                            component={'span'}
                                                            className={`${globalStyles.globalTextV14}`}
                                                        >
                                                            YEAR ENDING BALANCE
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell className={`${globalStyles.gblTableHeadCell} ${globalStyles.gblRightRadius}`} align='center' colSpan={3}>
                                                        <Typography
                                                            component={'span'}
                                                            className={`${globalStyles.globalTextV14}`}
                                                        >
                                                            SAVING
                                                        </Typography>
                                                    </TableCell>
                                                </TableRow>

                                                <TableRow className={`${globalStyles.gblSubHeaderRow}`}>
                                                    <TableCell colSpan={1} sx={{ borderBottom: 'none' }} className={`${globalStyles.gblLeftRadius}`} />
                                                    <TableCell
                                                        className={`${globalStyles.gblTableCellText}`}
                                                        sx={{ borderBottom: 'none' }}
                                                        align='center'
                                                    >
                                                        ACRYLIC
                                                    </TableCell>
                                                    <TableCell
                                                        className={`${globalStyles.gblTableCellText}`}
                                                        sx={{ borderBottom: 'none' }}
                                                        align='center'
                                                    >
                                                        HIGHLAND SMILES, PLLC
                                                    </TableCell>
                                                    <TableCell
                                                        className={`${globalStyles.gblTableCellText}`}
                                                        sx={{ borderBottom: 'none' }}
                                                        align='center'
                                                    >
                                                        DUE TO DIRECT FEES
                                                    </TableCell>
                                                    <TableCell
                                                        className={`${globalStyles.gblTableCellText}`}
                                                        sx={{ borderBottom: 'none' }}
                                                        align='center'
                                                    >
                                                        DUE TO PLAN ASSETS FEES
                                                    </TableCell>
                                                    <TableCell
                                                        className={`${globalStyles.gblTableCellText}`}
                                                        sx={{ borderBottom: 'none' }}
                                                        align='center'
                                                    >
                                                        TOTAL
                                                    </TableCell>
                                                </TableRow>
                                            </TableHead>

                                            <TableBody>
                                                {data?.map((row, index) => (
                                                    <TableRow key={index} className={`${globalStyles.gblTableBodyRow}`}>
                                                        <TableCell
                                                            className={`${globalStyles.gblTableCellText} ${globalStyles.gblLeftRadius}`}
                                                            align='left'
                                                        >
                                                            {row.year}
                                                        </TableCell>
                                                        <TableCell
                                                            className={`${globalStyles.gblTableCellText}`}
                                                            align='center'
                                                        >
                                                            {row.yearEndingBalance.acrylic}
                                                        </TableCell>
                                                        <TableCell
                                                            className={`${globalStyles.gblTableCellText}`}
                                                            align='center'
                                                        >
                                                            {row.yearEndingBalance.highlandSmiles}
                                                        </TableCell>
                                                        <TableCell
                                                            className={`${globalStyles.gblTableCellText}`}
                                                            align='center'
                                                        >
                                                            {row.saving.dueToDirectFees}
                                                        </TableCell>
                                                        <TableCell
                                                            className={`${globalStyles.gblTableCellText}`}
                                                            align='center'
                                                        >
                                                            {row.saving.dueToPlanAssetsFees}
                                                        </TableCell>
                                                        <TableCell
                                                            className={`${globalStyles.gblTableCellText} ${globalStyles.gblRightRadius}`}
                                                            align='center'
                                                        >
                                                            {row.saving.total}
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid size={{ xs: 12 }}>
                            <ComplianceDocument />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}

export default DashboardPlan401k