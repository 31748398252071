import React, { useContext, useEffect, useRef, useState } from 'react'

// STYLES
import styles from '../../../Styles/ComponentStyles/MainPages/dashboard.module.scss'
import globalStyles from '../../../Styles/global.module.scss'

import Grid from '@mui/material/Grid2';
import { Divider, Skeleton, Typography } from '@mui/material';
import { tabsConfig, keyIndicesTabs, keyIndicesData, baseURL } from '../../../Utils/Constant/Constant';
import GlobalButton from '../../../Utils/Layout/GlobalButton/GlobalButton';
import GlobalTabs from '../../../Utils/Layout/GlobalTabs/GlobalTabs';


// IMG
import orion from '../../../Assets/Dashboard/orion-advisor-solutions.png'
import revenue from '../../../Assets/Dashboard/advisor.png'
import market from '../../../Assets/Dashboard/market-and-sales.png'
import FeaturedCards from '../../../Components/FeaturedCards/FeaturedCards';

import img1 from '../../../Assets/Dashboard/clien-marketing.png'
import img2 from '../../../Assets/Dashboard/brokersalliance.png'
import img3 from '../../../Assets/Dashboard/lifebrain.png'
import img4 from '../../../Assets/Dashboard/retirementbrain.png';
import img5 from '../../../Assets/Dashboard/fa-portfolio-analysis.png';
import varifiedLogo from '../../../Assets/Dashboard/dash-varified-secure-logo.png';
import image401k from '../../../Assets/Dashboard/401k.png'

import lifebrain from '../../../Assets/Dashboard/lifebrain-bg.png'
import AppNavbar from '../../../Components/AppNavbar/AppNavbar';
import GlobalDialog from '../../../Utils/Layout/GlobalDialog/GlobalDialog';
import KeyIndecesDialog from '../../../Components/Dialogs/KeyIndicesDialog';
import KeyIndices from '../../../Components/KeyIndices/KeyIndices';
import AppStores from '../../../Components/Dialogs/AppStores';
import GlobalWarningDialog from '../../../Utils/Layout/GlobalWarningDialog/GlobalWarningDialog';
import PortfolioDialog from '../../../Components/Dialogs/PortfolioDialog';
import AdminAppNavbar from '../../../Components/AdminAppNavbar/AdminAppNavbar';
import { GlobalUserContext, GlobalUserContextPayload } from '../../../Context/GlobalUserDetailContext';
import { Outlet, useNavigate } from 'react-router-dom';
import DashboardNavbar from '../DashboardNavbar/DashboardNavbar';
import ComplianceDocument from '../../../Components/ComplianceDocument/ComplianceDocument';
import StocksMarquee from '../../../Components/StocksMarquee/StocksMarquee';
import AppClientMarketingDialog from '../../../Components/Dialogs/AppClientMarketingDialog';
import { DashboardContext, DashboardDetailPayload } from '../../../Context/DashboardDetailContext';


export default function DashoboardHome() {


    const {
        isAdminNavbarVisible,
    } = useContext(GlobalUserContext) as GlobalUserContextPayload

    const {
        dashboardDetails,
    } = useContext(DashboardContext) as DashboardDetailPayload

    const [featureData, setFeatureData] = useState<any>({
        Information: [
            {
                img: img1,
                text: "Client Marketing and Correspondence",
                click: true
            },
            {
                img: revenue,
                text: "Revenue App",
                path: "/dashboard/revenue",

            },
            {
                img: market,
                text: "Marketing And Sales Resources",
                path: "/dashboard/marketing-and-sales",
            },
            {
                img: orion,
                text: "Orion Advisor Solutions",
            },
        ],
        OtherApps: [
            {
                img: image401k,
                text: "401k",
                path: "/dashboard/plan401k",
            },
        ],
    });
    const [featuredAppsData, setFeaturedAppsData] = useState<any[]>([

        {
            img: img2,
            text: "Brokers Alliance MAC"
        },
        {
            img: img3,
            text: "LifeBrain.io"
        },
        {
            img: img4,
            text: "Retirement Brain"
        },
        {
            img: img5,
            text: "Portfolio Analysis or Account Intake"
        },
    ]);

    // CONST
    const navigate = useNavigate()

    // DIALOG FLAGS
    const [accessDialog, setFlagAccessDialog] = useState(false);
    const [flagPortfolioDialog, setFlagPortfolioDialog] = useState(false);
    const [flagAppStoreDialog, setFlagAppStoreDialog] = useState(false);
    const [flagClientMarketingDialog, setFlagClientMarketingDialog] = useState<boolean | null>(false);
    const [flagOpenKeyIndeciesDialog, setFlagOpenKeyIndeciesDialog] = useState(false);

    const [currentTab, setCurrentTab] = useState<any>("information");
    const [keyIndicesCurrentTab, setKeyIndicesCurrentTab] = useState("Indices");
    const [showAppName, setShowAppName] = useState<any>(null);

    // DATA STORED
    const [featuredAppData, setFeaturedAppData] = useState<any[] | null>(null)
    const [categorizedApps, setCategorizedApps] = useState<any>({});
    const [tabs, setTabs] = useState<any[]>([]);
    const [multiRedirectDialogData, setMultiRedirectDialogData] = useState<any[] | null>([])

    // const getUniqueTabsAndCategorizeApps = (apps: any): { tabs: any[]; categorizedApps: any } => {
    //     if (!apps) {
    //         return { tabs: [], categorizedApps: {} };
    //     }

    //     // Extract unique appSection values and format as { label, value }
    //     const sections = Array.from(new Set(apps.map((app: any) => app.appSection)));
    //     const baseURL = "https://mac.acrylicfinancial.com/assets/images/";
    //     const tabs = sections.map((section: any) => ({
    //         label: section,
    //         value: section.toLowerCase().replace(/\s+/g, "-"),
    //     }));

    //     const categorizedApps = sections.reduce((acc: any, section: any) => {
    //         acc[section.toLowerCase().replace(/\s+/g, "-")] = apps
    //             .filter((app: any) => app.appSection === section)
    //             .sort((a: any, b: any) => a.appOrder - b.appOrder)
    //             .map((app: any) => ({
    //                 appName: app.appName,
    //                 image: `${baseURL}${app.image}`,
    //                 redirectUrls: app.redirectUrl.map((urlObj: any) => ({
    //                     title: urlObj.title,
    //                     url: urlObj.url,
    //                 })),
    //                 flagAccess: app.flagAccess,
    //             }));
    //         return acc;
    //     }, {});


    //     console.log("categorizedAppsdasdasd", categorizedApps);


    //     setAppLinksDetails(categorizedApps)
    //     return { tabs, categorizedApps };
    // };


    // const { tabs, categorizedApps } = dashboardDetails?.apps ? getUniqueTabsAndCategorizeApps(dashboardDetails.apps) : { tabs: [], categorizedApps: {} };







    // LOGS



    // USE EFFECT -----
    useEffect(() => {
        if (!dashboardDetails || !dashboardDetails.apps) return;

        // Filter apps that are not installed and sort by appOrder
        const appNotInstalledFilterData = dashboardDetails.apps.filter((item) => item.flagInstalled == false).sort((a, b) => a.appOrder - b.appOrder);
        setFeaturedAppData(appNotInstalledFilterData);

        // Process the apps data to categorize
        const sections = Array.from(new Set(dashboardDetails?.apps?.map((app) => app.appSection)));

        const newTabs = sections.map((section) => ({
            label: section,
            value: section.toLowerCase().replace(/\s+/g, "-"),
        }));

        const categorizedData = sections.reduce((acc: any, section: any) => {
            acc[section.toLowerCase().replace(/\s+/g, "-")] = dashboardDetails.apps.filter((app: any) => app.appSection === section).sort((a: any, b: any) => a.appOrder - b.appOrder)
                .map((app: any) => ({
                    appName: app.appName,
                    image: `${baseURL}${app.image}`,
                    redirectUrls: app.redirectUrl.map((urlObj: any) => ({
                        title: urlObj.title,
                        url: urlObj.url,
                    })),
                    flagAccess: app.flagAccess,
                }));
            return acc;
        }, {});

        setTabs(newTabs);
        setCategorizedApps(categorizedData);
    }, [dashboardDetails]);


    // USE EFFECT -----
    const handleTabChange = (newValue: string) => {
        setCurrentTab(newValue);
    };

    const handleKeyIndicesTabChange = (event: React.SyntheticEvent, newValue: string) => {
        setKeyIndicesCurrentTab(newValue);
    };

    const handleApps = (event: React.MouseEvent<HTMLElement>, ele: any) => {
        event.preventDefault();
        setShowAppName(event);

        if (!ele.flagAccess) {
            setFlagAccessDialog(true);
            setShowAppName(ele.appName)
            return;
        }

        const redirectUrls = ele.redirectUrls || [];
        if (redirectUrls.length > 1) {
            setMultiRedirectDialogData(redirectUrls);
            setFlagClientMarketingDialog(true);
        }
        else if (redirectUrls.length == 1) {
            window.open(redirectUrls[0]?.url, "_blank");
        }
        else if (ele.appName == "Revenue App") {
            navigate("/dashboard/revenue")
        }

    }

    return (
        <>
            {flagOpenKeyIndeciesDialog &&
                <GlobalDialog
                    flagHeadSection={true}
                    maxWidth="lg"
                    dialogHead="Key Indices"
                    open={flagOpenKeyIndeciesDialog}
                    view={
                        <KeyIndecesDialog
                            keyIndicesTabs={keyIndicesTabs}
                            keyIndicesCurrentTab={keyIndicesCurrentTab}
                            handleKeyIndicesTabChange={handleKeyIndicesTabChange}
                            keyIndicesData={keyIndicesData}
                        />
                    }
                    onClose={() => { setFlagOpenKeyIndeciesDialog(false) }}
                />
            }

            {flagClientMarketingDialog &&
                <GlobalDialog
                    flagHeadSection={true}
                    maxWidth="sm"
                    dialogHead="Client Marketing & Correspondence"
                    open={flagClientMarketingDialog}
                    view={
                        <AppClientMarketingDialog
                            setFlagClientMarketingDialog={setFlagClientMarketingDialog}
                            multiRedirectDialogData={multiRedirectDialogData}
                        />
                    }
                    onClose={() => { setFlagClientMarketingDialog(false) }}
                />
            }

            {flagAppStoreDialog &&
                <GlobalDialog
                    flagHeadSection={true}
                    maxWidth="lg"
                    dialogHead="App Store"
                    open={flagAppStoreDialog}
                    view={
                        <AppStores
                            accessDialog={accessDialog}
                            setFlagAccessDialog={setFlagAccessDialog}
                            setShowAppName={setShowAppName}
                        />
                    }
                    onClose={() => { setFlagAppStoreDialog(false) }}
                />
            }

            {accessDialog && (
                <GlobalDialog
                    flagHeadSection={true}
                    maxWidth="sm"
                    dialogHead={showAppName.appName ? showAppName.appName : ""}
                    open={accessDialog}
                    view={
                        <GlobalWarningDialog
                            popupDescription={"This is a restricted app. You would need Permission to Install this App."}
                            primaryButtonText={"Request For Access"}
                            secondaryButtonText={"Close"}
                            primaryButtonWidth="175px"
                            secondaryButtonWidth="175px"
                            handleClose={() => { setFlagAccessDialog(false) }}
                        />
                    }
                    onClose={() => { setFlagAccessDialog(false) }}
                />
            )}

            {flagPortfolioDialog && (
                <GlobalDialog
                    open={flagPortfolioDialog}
                    flagHeadSection={true}
                    maxWidth="md"
                    dialogHead="Portfolio"
                    view={
                        <PortfolioDialog />
                    }
                    onClose={() => { setFlagPortfolioDialog(false) }}
                />
            )}

            <Grid container>
                <AppNavbar navbarHeadingName={"Dashboard"} />
                <Grid size={{ xs: 12 }} sx={{ margin: "10px 0px" }}>
                    <Grid container >
                        <Grid size={{ xs: 12 }} className={`${styles.dashboardMarqueMainItem}`}>
                            <StocksMarquee />
                        </Grid>

                        <Grid size={{ xs: 12 }}>
                            <Grid container sx={{ justifyContent: 'space-between', }}>
                                <Grid size={{ xs: 8.45 }} className={`${styles.dashboardLeftSection}`} >
                                    <Grid container>

                                        <Grid size={{ xs: 12 }} className={`${styles.dsGlobalFeatureCardStyle} ${styles.dsFeatureSectionItem} `}>
                                            <Grid container>
                                                <Grid size={{ xs: 12 }} className={`${styles.dsCardHeadItem}`}>
                                                    <Typography component={'span'} className={`${globalStyles.globalHeadV2}`}>
                                                        My Apps
                                                    </Typography>
                                                </Grid>

                                                <Grid size={{ xs: 12 }} className={`${globalStyles.globalTabMainItem}`}>
                                                    <Grid container>
                                                        <Grid size={{ xs: 12 }}>
                                                            <GlobalTabs
                                                                tabs={tabs}
                                                                currentTab={currentTab}
                                                                onChange={(event: React.SyntheticEvent, newValue: string) => handleTabChange(newValue)}
                                                                sxTabStyle={{
                                                                    textTransform: 'none',
                                                                    color: '#7E7E7E',
                                                                    marginRight: '20px',
                                                                    fontWeight: 'normal',
                                                                    fontSize: '1rem',
                                                                    '&.Mui-selected': {
                                                                        color: '#ffffff',
                                                                        fontWeight: 'bold',
                                                                    }
                                                                }}
                                                            />
                                                        </Grid>
                                                        <Grid size={{ xs: 12 }}>
                                                            <Grid container>
                                                                <Grid size={{ xs: 12 }}>
                                                                    {/* {tabsConfig &&
                                                                
                                                            } */}
                                                                </Grid>
                                                                <Grid size={{ xs: 12 }}></Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>

                                                <Grid size={{ xs: 12 }} >
                                                    <Grid container className={`${styles.featureCardContainer}`}>
                                                        {(categorizedApps && categorizedApps[currentTab])?.map((ele: any, index: number) => (
                                                            <Grid
                                                                key={index}
                                                                className={`${styles.featureCardAppsItem}`}
                                                                sx={{ padding: "10px 0px" }}
                                                            >
                                                                <FeaturedCards
                                                                    featureName={ele.appName}
                                                                    featureImg={ele.image}
                                                                    featureStyling={{
                                                                        fontSize: "15px",
                                                                        fontWeight: "500",
                                                                    }}
                                                                    onClick={(event: any) => {
                                                                        handleApps(event, ele)
                                                                        // console.log("ele", ele);
                                                                        // setShowAppName(ele)
                                                                        // if (!ele.flagAccess) {
                                                                        //     setFlagAccessDialog(true)
                                                                        //     return;
                                                                        // }
                                                                        // if (ele.path) {
                                                                        //     window.open(ele.path, "_blank");
                                                                        // }
                                                                        // else if (ele.flagAccess) {
                                                                        //     setFlagClientMarketingDialog(true);
                                                                        // }
                                                                    }}
                                                                />
                                                            </Grid>
                                                        ))}
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>

                                        <Grid size={{ xs: 12 }} className={`${styles.dsGlobalFeatureCardStyle} ${styles.dsPorfolioCards} ${styles.dsGlobalCardStyles}`}>
                                            <Grid container>
                                                <Grid size={{ xs: 12 }} className={`${styles.dsPortfolioSectionItem}`}>
                                                    <Grid container sx={{ justifyContent: "space-between", gap: "10px" }}>
                                                        <Grid size={{ xs: 9 }}>
                                                            <Typography
                                                                component='span'
                                                                className={`${globalStyles.globalHeadV2}`}
                                                            >
                                                                Portfolio
                                                            </Typography>
                                                        </Grid>
                                                        <Grid size={{ xs: 2 }} sx={{ textAlign: 'end' }}>
                                                            <GlobalButton
                                                                value='Add Portfolio'
                                                                className={`${globalStyles.globleButtonStyleV1}`}
                                                                onClick={() => { setFlagPortfolioDialog(true) }}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid size={{ xs: 12 }} className={`${styles.dsPortfolioSectionContentItem}`}>
                                                    <Typography component={'span'} className={`${globalStyles.globalHeadV1}`}>
                                                        No portfolio has been created yet.
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>

                                        <Grid size={{ xs: 12 }} className={`${styles.dsGlobalFeatureCardStyle} ${styles.dsGlobalCardStyles}`}>
                                            <Grid container>
                                                <Grid size={{ xs: 12 }}>
                                                    <Grid container>
                                                        <Grid size={{ xs: 10 }} className={`${styles.dsCardHeadItem}`}>
                                                            <Typography component={'span'} className={`${globalStyles.globalHeadV2}`}>
                                                                Featured Apps
                                                            </Typography>
                                                        </Grid>
                                                        <Grid size={{ xs: 2 }} sx={{ textAlign: 'end' }}>
                                                            <GlobalButton
                                                                value='View App Store'
                                                                className={`${globalStyles.globleButtonStyleV1}`}
                                                                onClick={() => { setFlagAppStoreDialog(true) }}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </Grid>

                                                <Grid size={{ xs: 12 }} >
                                                    <Grid container className={`${styles.featureCardContainer}`}>
                                                        {featuredAppData && featuredAppData?.map((ele: any) => {
                                                            const image = baseURL + "" + ele.image
                                                            return (
                                                                <>
                                                                    <Grid className={`${styles.featureCardAppsItem}`} sx={{ padding: "10px 0px" }}>
                                                                        <Grid container >
                                                                            <FeaturedCards
                                                                                featureName={ele.appName}
                                                                                featureImg={image ? image : ""}
                                                                                featureStyling={{
                                                                                    fontSize: "15px",
                                                                                    fontWeight: "500"
                                                                                }}
                                                                                onClick={() => {
                                                                                    setShowAppName(ele)
                                                                                    if (!ele.flagAccess) {
                                                                                        setFlagAccessDialog(true)
                                                                                        return;
                                                                                    }
                                                                                }}
                                                                            />
                                                                        </Grid>
                                                                    </Grid>
                                                                </>
                                                            )
                                                        })}
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid size={{ xs: 3.45 }} className={`${styles.dashboardRightSection}`}>
                                    <Grid container>
                                        <Grid size={{ xs: 12 }} className={`${styles.lifebrainItem} ${styles.dsFeatureSectionItem} `}>
                                            <img
                                                src={lifebrain}
                                                alt=""
                                                style={{ width: "100%", height: '100%' }}
                                            />
                                        </Grid>

                                        <Grid size={{ xs: 12 }} className={`${styles.dsGlobalFeatureCardStyle} ${styles.dsGlobalCardStyles}`}>
                                            <Grid container>
                                                <Grid size={{ xs: 12 }} sx={{ margin: "0px 0px 10px" }}>
                                                    <Grid container>
                                                        <Grid size={{ xs: 6 }}>
                                                            <Typography component={'span'} className={`${globalStyles.globalHeadV2}`}>
                                                                Key Indices
                                                            </Typography>
                                                        </Grid>
                                                        <Grid size={{ xs: 6 }} sx={{ textAlign: 'end' }}>
                                                            <GlobalButton
                                                                value='Full View'
                                                                className={`${globalStyles.globleButtonStyleV1}`}
                                                                onClick={() => {
                                                                    setFlagOpenKeyIndeciesDialog(true)
                                                                }}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </Grid>

                                                <Grid size={{ xs: 12 }} className={`${globalStyles.globalTabMainItem}`}>
                                                    <GlobalTabs
                                                        tabs={keyIndicesTabs}
                                                        currentTab={keyIndicesCurrentTab}
                                                        onChange={(event: React.SyntheticEvent, newValue: string) => handleKeyIndicesTabChange(event, newValue)}
                                                        sxTabStyle={{
                                                            textTransform: 'none',
                                                            color: '#7E7E7E',
                                                            marginRight: '10px',
                                                            fontWeight: 'normal',
                                                            fontSize: '15px',
                                                            '&.Mui-selected': {
                                                                color: '#ffffff',
                                                                fontWeight: 'bold',
                                                            }
                                                        }}
                                                    />
                                                </Grid>

                                                <Grid size={{ xs: 12 }} className={`${styles.keyIndicesValueitem}`}>
                                                    <Grid container>
                                                        <KeyIndices
                                                            keyIndicesData={keyIndicesData}
                                                            flagVisibleDivider={true}
                                                        />
                                                    </Grid>
                                                </Grid>

                                                <Grid size={{ xs: 12 }} sx={{ textAlign: 'center', margin: "15px 0px 0px" }}>
                                                    <Typography component={'span'} className={`${globalStyles.gblC00B7FF} ${globalStyles.globalTextV16}`}>
                                                        Market Data
                                                    </Typography>
                                                    &nbsp;
                                                    <Typography component={'span'} className={`${globalStyles.gblCWhite} ${globalStyles.globalTextV16}`}>
                                                        by Trading View
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>

                                        <Grid size={{ xs: 12 }} className={`${styles.dsGlobalFeatureCardStyle} ${styles.dsGlobalCardStyles} `}>
                                            <Typography component={'span'} className={`${globalStyles.globalHeadV2}`}>
                                                Schwab Advisor Center coming soon
                                            </Typography>
                                        </Grid>

                                        <Grid size={{ xs: 12 }} className={`${styles.dsGlobalFeatureCardStyle} ${styles.dsGlobalCardStyles} `}>
                                            <Typography component={'span'} className={`${globalStyles.globalHeadV2}`}>
                                                Your Acrylic Team
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid size={{ xs: 12 }}>
                    <Grid container>
                        <Grid size={{ xs: 12 }} sx={{ textAlign: 'center', margin: '10px 0px' }}>
                            <img
                                src={varifiedLogo}
                                alt=""
                            />
                        </Grid>
                        <Grid size={{ xs: 12 }}>
                            <ComplianceDocument />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

        </>
    )
}
