import { Button, IconButton } from "@mui/material";
// import deleteConfirmIcon from "../../assets/Images/popupDeleteIcon.svg";
import globalStyles from "../../../Styles/global.module.scss";
import styles from "../../../Styles/ComponentStyles/GlobalComponentStyles/WarningDialog.module.scss"

import Grid from '@mui/material/Grid2';
import Icons from "../Icons/Icons";
import GlobalButton from "../GlobalButton/GlobalButton";

interface DeletePopupProps {
    popupTitle?: string;
    popupDescription?: string;
    handleClose?: () => void;
    onDelete?: () => void;
    primaryButtonText?: string;
    secondaryButtonText?: string
    primaryButtonWidth?: string
    secondaryButtonWidth?: string
}

export default function GlobalWarningDialog(props: DeletePopupProps) {

    const {
        popupDescription,
        handleClose,
        onDelete,
        popupTitle,
        primaryButtonText,
        secondaryButtonText,
        secondaryButtonWidth,
        primaryButtonWidth
    } = props

    return (
        <Grid container
            // className={globalStyles.demo}
            direction={"column"}
            textAlign={"center"}
            justifyContent={"center"}
            sx={{ padding: "20px" }}
        >
            <Grid container >
                <Grid size={{ xs: 12 }} className={`${styles.iconItem}`}>
                    <Icons
                        iconName="warningIcon"
                        iconWidth="150px"
                        iconHeight="150px"
                        iconColor="#FFF7ED"
                    />
                </Grid>
                <Grid size={{ xs: 12 }}>
                    <span className={styles.dialogDesc}>
                        {popupDescription}
                    </span>
                </Grid>
                <Grid size={{ xs: 12 }}>
                    <Grid container className={`${styles.buttonContainer}`}>
                        <Grid>
                            <GlobalButton
                                value={primaryButtonText ? primaryButtonText : ""}
                                className={styles.primaryButtonStyles}
                                size="medium"
                                sx={{
                                    width: primaryButtonWidth
                                }}
                            />
                        </Grid>
                        <Grid>
                            <GlobalButton
                                size="medium"
                                variant="outlined"
                                className={styles.secondaryButtonStyle}
                                value={secondaryButtonText ? secondaryButtonText : ""}
                                onClick={handleClose}
                                sx={{
                                    width: secondaryButtonWidth
                                }}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}