import React from 'react'
import styles from "../../Styles/ComponentStyles/MainPages/RevenueApp.module.scss"
import globalStyles from "../../Styles/global.module.scss"

import Grid from '@mui/material/Grid2';
import AppNavbar from '../../Components/AppNavbar/AppNavbar';
import { Checkbox, FormControlLabel, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import GlobalTextfield from '../../Utils/Layout/GlobalTextfield/GlobalTextfield';
import GlobalSelect from '../../Utils/Layout/GlobalSelect/GlobalSelect';
import Icons from '../../Utils/Layout/Icons/Icons';
import GlobalButton from '../../Utils/Layout/GlobalButton/GlobalButton';
import { genericRevenueAppData, revenueAppData } from '../../Utils/Constant/Constant';

const RevenueApp = () => {
    return (
        <>
            <AppNavbar navbarHeadingName={"Revenue App"} />
            <Grid container>
                <Grid size={{ xs: 12 }}>
                    <Grid container>
                        <Grid size={{ xs: 12 }} sx={{ margin: "30px 0px" }}>
                            <Grid container sx={{ columnGap: "40px", rowGap: "20px", alignItems: "center" }}>
                                <Grid className={`${styles.revenueAppFieldItem}`}>
                                    <Grid container className={`${styles.revenueHeadFieldContainer}`}>
                                        <Grid size={{ xs: 3 }}>
                                            <Typography
                                                component={"span"}
                                                className={`${globalStyles.gblCWhite} ${globalStyles.globalTextV16}`}
                                            >
                                                Mode
                                            </Typography>
                                        </Grid>
                                        <Grid size={{ xs: 8 }}>
                                            <GlobalSelect
                                                size='small'
                                                fullWidth
                                                value={null}
                                                className={`${globalStyles.gblDropdownStyles}`}
                                                options={[
                                                    {
                                                        label: "Test",
                                                        value: "test"
                                                    },
                                                    {
                                                        label: "Test2",
                                                        value: "test2"
                                                    },
                                                ]}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid className={`${styles.revenueAppEmailFieldItem}`}>
                                    <Grid container className={`${styles.revenueHeadFieldContainer}`}>
                                        <Grid size={{ xs: 4 }}>
                                            <Typography
                                                component={"span"}
                                                className={`${globalStyles.gblCWhite} ${globalStyles.globalTextV16}`}
                                            >
                                                Test Email Address
                                            </Typography>
                                        </Grid>
                                        <Grid size={{ xs: 8 }} className={`${globalStyles.gblTextfieldItem}`}>
                                            <GlobalTextfield
                                                fullWidth
                                                value={""}
                                                size='small'
                                                placeholder='Enter Email Address'
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid className={`${styles.revenueAppFieldItem}`}>
                                    <Grid container className={`${styles.revenueHeadFieldContainer}`}>
                                        <Grid size={{ xs: 3 }}>
                                            <Typography
                                                component={"span"}
                                                className={`${globalStyles.gblCWhite} ${globalStyles.globalTextV16}`}
                                            >
                                                Month
                                            </Typography>
                                        </Grid>
                                        <Grid size={{ xs: 8 }} >
                                            <GlobalSelect
                                                size='small'
                                                fullWidth
                                                value={null}
                                                className={`${globalStyles.gblDropdownStyles}`}
                                                options={[
                                                    {
                                                        label: "January",
                                                        value: "january"
                                                    },
                                                    {
                                                        label: "Fab",
                                                        value: "fab"
                                                    },
                                                ]}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid className={`${styles.revenueAppFieldItem}`}>
                                    <Grid container className={`${styles.revenueHeadFieldContainer}`}>
                                        <Grid size={{ xs: 3 }}>
                                            <Typography
                                                component={"span"}
                                                className={`${globalStyles.gblCWhite} ${globalStyles.globalTextV16}`}
                                            >
                                                Year
                                            </Typography>
                                        </Grid>
                                        <Grid size={{ xs: 8 }}>
                                            <GlobalSelect
                                                size='small'
                                                fullWidth
                                                value={null}
                                                className={`${globalStyles.gblDropdownStyles}`}
                                                options={[
                                                    {
                                                        label: "2019",
                                                        value: "2019"
                                                    },
                                                    {
                                                        label: "2020",
                                                        value: "2020"
                                                    },
                                                ]}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid >
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                size="small"
                                                checked={false}
                                                icon={<Icons iconName="checkbox" iconWidth="20px" iconHeight="20px" />}
                                                checkedIcon={<Icons iconName="checkedIcon" iconWidth="20px" iconHeight="20px" />}
                                                sx={{ padding: "0px 8px" }}
                                            />
                                        }
                                        label="Remember Me"
                                        sx={{ color: "#ffffff" }}
                                    />
                                </Grid>

                                <Grid size={{ xs: "grow" }} sx={{ textAlign: "end" }}>
                                    <GlobalButton
                                        value='Groups'
                                        size='medium'
                                        className={`${globalStyles.gblContainedBtn}`}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid size={{ xs: 12 }}>
                            <TableContainer>
                                <Table size='small' className={`${globalStyles.gblTableStyles}`}>
                                    <TableHead className={`${globalStyles.gblTableHeadStyles}`}>
                                        <TableRow>
                                            <TableCell
                                                className={`${globalStyles.gblTableHeadCell}  ${globalStyles.gblLeftRadius}`}
                                                sx={{ fontSize: "14px" }}
                                            >
                                                USER NAME
                                            </TableCell>
                                            <TableCell
                                                className={`${globalStyles.gblTableHeadCell}`}
                                                sx={{ fontSize: "14px" }}
                                            >
                                                EMAIL
                                            </TableCell>
                                            <TableCell
                                                className={`${globalStyles.gblTableHeadCell} `}
                                                sx={{ fontSize: "14px" }}
                                            >
                                                REP CODE
                                            </TableCell>
                                            <TableCell
                                                className={`${globalStyles.gblTableHeadCell} `}
                                                sx={{ fontSize: "14px" }}
                                            >
                                                INCLUDE?
                                            </TableCell>
                                            <TableCell
                                                className={`${globalStyles.gblTableHeadCell} `}
                                                sx={{ fontSize: "14px" }}
                                            >
                                                GROUP
                                            </TableCell>
                                            <TableCell
                                                className={`${globalStyles.gblTableHeadCell} `}
                                                sx={{ fontSize: "14px" }}
                                            >
                                                PAYOUT AMOUNT
                                            </TableCell>
                                            <TableCell
                                                className={`${globalStyles.gblTableHeadCell} `}
                                                sx={{ fontSize: "14px" }}
                                            >
                                                ACCOUNTS
                                            </TableCell>
                                            <TableCell
                                                className={`${globalStyles.gblTableHeadCell} `}
                                                sx={{ fontSize: "14px" }}
                                            >
                                                ACCOUNTS IN ERROR
                                            </TableCell>
                                            <TableCell
                                                className={`${globalStyles.gblTableHeadCell} `}
                                                sx={{ fontSize: "14px" }}
                                            >
                                                BILLABLE MV
                                            </TableCell>
                                            <TableCell
                                                className={`${globalStyles.gblTableHeadCell} `}
                                                sx={{ fontSize: "14px" }}
                                            >
                                                ADVISOR SPLIT ADJUSTMENT
                                            </TableCell>
                                            <TableCell
                                                className={`${globalStyles.gblTableHeadCell} `}
                                                sx={{ fontSize: "14px" }}
                                            >
                                                EXCLUSIONS
                                            </TableCell>
                                            <TableCell
                                                className={`${globalStyles.gblTableHeadCell} `}
                                                sx={{ fontSize: "14px" }}
                                            >
                                                DISCOUNTS
                                            </TableCell>
                                            <TableCell
                                                className={`${globalStyles.gblTableHeadCell} ${globalStyles.gblRightRadius}`}
                                                sx={{ fontSize: "14px" }}
                                            >
                                                CASH SHORTAGES
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {revenueAppData?.map((row: any, index: number) => (
                                            <TableRow
                                                key={index}
                                                className={`${globalStyles.gblTableBodyRow}`}
                                            >
                                                <TableCell
                                                    className={`${globalStyles.gblTableCellText} ${globalStyles.gblLeftRadius}`}
                                                >
                                                    {row.userName}
                                                </TableCell>

                                                <TableCell
                                                    className={`${globalStyles.gblTableCellText}`}
                                                >
                                                    {row.email}
                                                </TableCell>

                                                <TableCell
                                                    className={`${globalStyles.gblTableCellText}`}
                                                >
                                                    {row.repcode}
                                                </TableCell>

                                                <TableCell
                                                    className={`${globalStyles.gblTableCellText}`}
                                                >
                                                    <GlobalSelect
                                                        options={genericRevenueAppData.include.map((option: any) => ({ label: option.groupName, value: option.id }))}
                                                        value={row.include}
                                                        fullWidth
                                                        size='small'
                                                        // onChange={(event: any) => { handleSelectChange(event) }}
                                                        className={`${styles.subscribeSelectStyles}`}
                                                    />
                                                </TableCell>

                                                <TableCell
                                                    className={`${globalStyles.gblTableCellText}`}
                                                >
                                                    {row.groupValue}
                                                </TableCell>

                                                <TableCell
                                                    className={`${globalStyles.gblTableCellText}`}
                                                >
                                                    {row.payoutAmount}
                                                </TableCell>

                                                <TableCell
                                                    className={`${globalStyles.gblTableCellText}`}
                                                >
                                                    {row.accounts}
                                                </TableCell>

                                                <TableCell
                                                    className={`${globalStyles.gblTableCellText}`}
                                                >
                                                    {row.accountInError}
                                                </TableCell>

                                                <TableCell
                                                    className={`${globalStyles.gblTableCellText}`}
                                                >
                                                    {row.billableMv}
                                                </TableCell>

                                                <TableCell
                                                    className={`${globalStyles.gblTableCellText}`}
                                                >
                                                    {row.splitAdjustment}
                                                </TableCell>

                                                <TableCell
                                                    className={`${globalStyles.gblTableCellText}`}
                                                >
                                                    {row.exclusions}
                                                </TableCell>

                                                <TableCell
                                                    className={`${globalStyles.gblTableCellText}`}
                                                >
                                                    {row.discounts}
                                                </TableCell>

                                                <TableCell
                                                    className={`${globalStyles.gblTableCellText} ${globalStyles.gblRightRadius}`}
                                                >
                                                    {row.cashShortages}
                                                </TableCell>

                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}

export default RevenueApp