
import React, { useContext, useEffect, useRef, useState } from 'react'
import Grid from '@mui/material/Grid2';
import lifebrain from '../../Assets/Dashboard/lifebrain-bg.png'
import AppNavbar from '../../Components/AppNavbar/AppNavbar';
import { Outlet, useNavigate } from 'react-router-dom';



const Dashboard = () => {

    return (
        <>
            <Grid container>
                <Grid size={{ xs: 12 }}>
                    <Outlet />
                </Grid>
            </Grid >
        </>
    )
}

export default Dashboard