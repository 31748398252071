import React from 'react'
import styles from '../../Styles/ComponentStyles/MainPages/dashboard.module.scss'
import globalStyles from '../../Styles/global.module.scss'
import Grid from '@mui/material/Grid2';
import { Divider, Typography } from '@mui/material';

interface keyDataArray {
    shareName: string,
    value: string,
    shareNameType: string,
    shareNameTypeValue: string
}

interface keyIndicesProps {
    keyIndicesData: keyDataArray[],
    flagVisibleDivider: boolean
}

const KeyIndices = (props: keyIndicesProps) => {

    const { keyIndicesData, flagVisibleDivider } = props

    return (
        <>
            <Grid size={{ xs: 12 }} >
                <Grid container>
                    {keyIndicesData?.map((data: any) => {
                        return (
                            <>
                                <Grid size={{ xs: 12 }}>
                                    <Grid container sx={{ justifyContent: 'space-between', padding: "5px", margin: '5px 0px' }}>
                                        <Grid size={{ xs: 5.8 }}>
                                            <Grid container>
                                                <Grid size={{ xs: 12 }}>
                                                    <Typography component={'span'} className={`${globalStyles.gblCWhite} ${globalStyles.globalTextV16}`}>
                                                        {data.shareName}
                                                    </Typography>
                                                </Grid>
                                                <Grid size={{ xs: 12 }} className={`${globalStyles.gblCD9D9D9} ${globalStyles.globalTextV14} `}>
                                                    <Typography component={'span'} >
                                                        {data.shareNameType}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid size={{ xs: 5.8 }} sx={{ textAlign: 'end' }}>
                                            <Grid container>
                                                <Grid size={{ xs: 12 }}>
                                                    <Typography component={'span'} className={`${globalStyles.gblCWhite} ${globalStyles.globalTextV16}`}>
                                                        {data.value}
                                                    </Typography>
                                                </Grid>
                                                <Grid size={{ xs: 12 }}>
                                                    <Typography component={'span'} className={`${globalStyles.gblC93FF00} ${globalStyles.globalTextV14}`}>
                                                        {data.shareNameTypeValue}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                {flagVisibleDivider &&
                                    <Grid size={{ xs: 12 }}>
                                        <Divider sx={{ borderColor: "#505968" }} />
                                    </Grid>
                                }
                            </>
                        )
                    })}
                </Grid>
            </Grid>
        </>
    )
}

export default KeyIndices