// GlobalSnackbar.tsx
import React, { createContext, useContext, useState, ReactNode } from 'react';
import { Snackbar, Alert, AlertColor } from '@mui/material';

// Define the shape of the alert state
interface SnackbarState {
    open: boolean;
    message: string;
    severity: AlertColor;
}

interface SnackbarContextType {
    showSnackbar: (message: string, severity: AlertColor) => void;
    closeSnackbar: () => void;
}

const SnackbarContext = createContext<SnackbarContextType | undefined>(undefined);

// Custom hook to access the snackbar context
export const useSnackbar = () => {
    const context = useContext(SnackbarContext);
    if (!context) {
        throw new Error("useSnackbar must be used within a SnackbarProvider");
    }
    return context;
};

// Snackbar provider component
export const SnackbarProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [alertState, setAlertState] = useState<SnackbarState>({
        open: false,
        message: '',
        severity: 'success',
    });

    // Function to show the Snackbar
    const showSnackbar = (message: string, severity: AlertColor) => {
        setAlertState({
            open: true,
            message,
            severity,
        });

        // Auto-hide after 4 seconds (if you want to trigger the close programmatically)
        setTimeout(() => {
            setAlertState((prevState) => ({
                ...prevState,
                open: false,
            }));
        }, 4000); // Change the duration as needed
    };

    // Function to close the Snackbar
    const closeSnackbar = () => {
        setAlertState((prevState) => ({
            ...prevState,
            open: false,
        }));
    };

    const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        closeSnackbar(); // Close the Snackbar when not dismissed by a clickaway event
    };

    return (
        <SnackbarContext.Provider value={{ showSnackbar, closeSnackbar }}>
            {children}
            <Snackbar
                open={alertState.open}
                autoHideDuration={4000} // Automatically hide the snackbar after 4 seconds
                onClose={handleClose}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
                <Alert onClose={handleClose} severity={alertState.severity} sx={{ width: '100%' }}>
                    {alertState.message}
                </Alert>
            </Snackbar>
        </SnackbarContext.Provider>
    );
};
