import React from 'react'


import { AppBar, Avatar, Divider, IconButton } from '@mui/material';
import Grid from '@mui/material/Grid2';
import acrylicFinancial from '../../Assets/Logos/AcrylicFinanciallogo.png'
import myadvisoryLogo from '../../Assets/Logos/myadviosrylogo.png'
import globalStyles from '../../Styles/global.module.scss'
import profile from '../../Assets/General/profile.png'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

const AdminAppNavbar = () => {

    return (
        <>
            <AppBar component={'nav'} className={`${globalStyles.appBarNav}`}>
                <Grid container>
                    <Grid size={{ xs: 12 }} className={`${globalStyles.appNavMainItem}`}>
                        <Grid container>
                            <Grid size={{ xs: 6 }}>
                                <Grid container>
                                    <Grid>
                                        <img src={myadvisoryLogo} alt="" />
                                    </Grid>
                                    <Divider orientation="vertical" variant="middle" flexItem sx={{ borderColor: "white", margin: '5px 5px' }} />
                                    <Grid>
                                        <img src={acrylicFinancial} alt="" />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid size={{ xs: 6 }}>
                                <Grid container sx={{ justifyContent: 'end' }}>
                                    <Grid className={`${globalStyles.navbarProfileItem}`}>
                                        <Grid container className={`${globalStyles.navProfileContainr}`}>
                                            <Grid className={`${globalStyles.profileItem}`}>
                                                <Avatar
                                                    src={profile}
                                                    sx={{
                                                        width: "100%",
                                                        height: "100%"
                                                    }}
                                                />
                                            </Grid>
                                            <Grid className={`${globalStyles.profileArrowItem}`}>
                                                <IconButton sx={{ padding: "0px" }}>
                                                    <KeyboardArrowDownIcon />
                                                </IconButton>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </AppBar>
        </>
    )
}

export default AdminAppNavbar