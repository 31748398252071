import React, { useContext } from 'react'
import globalStyles from '../../Styles/global.module.scss'

import Grid from '@mui/material/Grid2';
import Marquee from "react-fast-marquee";
import { Typography } from '@mui/material';
import { DashboardContext, DashboardDetailPayload } from '../../Context/DashboardDetailContext';

const StocksMarquee = () => {

    const {
        stockMarqueDetails
    } = useContext(DashboardContext) as DashboardDetailPayload


    return (
        <>
            <Grid container>
                <Grid size={{ xs: 12 }}>
                    <Marquee style={{ background: "black", padding: "5px 0px" }}>
                        {stockMarqueDetails.map((ele: any) => {
                            return (
                                <>
                                    <Grid container sx={{ alignItems: "center", gap: "2px", margin: "0px 10px" }}>
                                        <Grid sx={{ display: 'flex', margin: "0px 5px" }}>
                                            <img src={ele.img} style={{ width: '18px', height: 'auto' }} alt="" />
                                        </Grid>
                                        <Grid>
                                            <Typography component={'span'} className={`${globalStyles.gblCWhite} ${globalStyles.globalTextV14}`}>
                                                {ele.stockText}
                                            </Typography>
                                        </Grid>
                                        <Grid sx={{ display: 'flex' }}>
                                            {ele.stockArrow}
                                        </Grid>
                                        <Grid sx={{ display: 'flex' }}>
                                            <Typography component={'span'} className={`${globalStyles.gblCWhite} ${globalStyles.globalTextV14}`}>
                                                {ele.stockPrice}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </>
                            )
                        })}
                    </Marquee>
                </Grid>
            </Grid>
        </>
    )
}

export default StocksMarquee