import React, { useState } from 'react'
import Grid from '@mui/material/Grid2';
import styles from "../../../Styles/ComponentStyles/MainPages/dashboard.module.scss"
import globalStyles from "../../../Styles/global.module.scss"

import AppNavbar from '../../../Components/AppNavbar/AppNavbar';
import DashboardNavbar from '../DashboardNavbar/DashboardNavbar';
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { pdfData } from '../../../Utils/Constant/Constant';
import PdfCards from '../../../Components/PdfCards/PdfCards';
import { useNavigate } from 'react-router-dom';

const DashboardMarketingAndSalesResources = () => {

    const [expanded, setExpanded] = useState<string | false>(false);
    const navigate = useNavigate()

    const handleChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
        setExpanded(isExpanded ? panel : false);
    };

    const handleClick = () => {
        navigate('/')
    }

    return (
        <>
            <AppNavbar navbarHeadingName={"Dashboard"} />
            <DashboardNavbar onClick={handleClick} headName={"Marketing And Sales Resources"} />
            <Grid container className={`${styles.dsMarketingContainer}`}>
                <Grid size={{ xs: 12 }} className={`${styles.dsMarketing}`}>
                    <Grid container>
                        <Grid size={{ xs: 12 }}>

                        </Grid>

                        <Grid size={{ xs: 12 }} >
                            {pdfData.map((ele) => {
                                return (
                                    <>
                                        <Accordion
                                            expanded={expanded === ele.accordianName}
                                            onChange={handleChange(ele.accordianName)}
                                            className={`${styles.dsGlobalFeatureCardStyle}`}
                                            sx={{ padding: "0px !important" }}
                                        >
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon sx={{ color: "#ffffff" }} />}
                                                aria-controls="panel1-content"
                                                id="panel1-header"
                                            >
                                                <Typography className={`${globalStyles.gblCWhite} ${globalStyles.globalTextV16}`}>{ele.accordianName}</Typography>
                                            </AccordionSummary>

                                            <AccordionDetails>
                                                <Grid size={{ xs: 12 }}>
                                                    <Grid container sx={{ gap: "20px" }}>
                                                        {ele.accordianData.map((data) => {
                                                            return (
                                                                <>
                                                                    <Grid className={`${styles.pdfCarditem}`}>
                                                                        <PdfCards
                                                                            pdfMainName={data.pdfHeadName}
                                                                            pdfSubName={data.pdfSubHeadName}
                                                                        />
                                                                    </Grid>
                                                                </>
                                                            )
                                                        })}
                                                    </Grid>
                                                </Grid>
                                            </AccordionDetails>
                                        </Accordion>
                                    </>
                                )
                            })}

                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}

export default DashboardMarketingAndSalesResources