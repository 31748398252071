import React, { useState } from 'react'
import styles from "../../../Styles/ComponentStyles/MainPages/Member.module.scss"
import globalStyles from "../../../Styles/global.module.scss"

import Grid from '@mui/material/Grid2';
import GlobalTextfield from '../../../Utils/Layout/GlobalTextfield/GlobalTextfield';
import { Accordion, AccordionDetails, AccordionSummary, Checkbox, FormControlLabel, IconButton, SelectChangeEvent, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import AppNavbar from '../../../Components/AppNavbar/AppNavbar';
import DashboardNavbar from '../../Dashboard/DashboardNavbar/DashboardNavbar';
import { useNavigate } from 'react-router-dom';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { macSampleData, marketingReview, subscribedApps } from '../../../Utils/Constant/Constant';
import Icons from '../../../Utils/Layout/Icons/Icons';
import GlobalSelect from '../../../Utils/Layout/GlobalSelect/GlobalSelect';
import GlobalAutocomplate from '../../../Utils/Layout/GlobalAutocomplate/GlobalAutoComplate';


const ViewUsers = () => {

    const navigate = useNavigate()
    const [expanded, setExpanded] = useState<string | false>(false);
    const [selectedValue, setSelectedValue] = useState<string | number>('option1');

    const handleChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
        setExpanded(isExpanded ? panel : false);
    };

    const handleSelectChange = (event: SelectChangeEvent<any>) => {
        setSelectedValue(event.target.value);
    };

    const handleClick = () => {
        navigate("/")
    }

    return (
        <>
            <DashboardNavbar headName="Test user 1" onClick={handleClick} />
            <Grid container>
                <Grid size={{ xs: 12 }}>
                    <Grid container>
                        <Grid size={{ xs: 12 }} sx={{ margin: "30px 0px" }}>
                            <Grid container sx={{ gap: "40px" }}>
                                <Grid >
                                    <Grid container className={`${styles.memberHeadFieldContainer}`}>
                                        <Grid >
                                            <Typography
                                                component={"span"}
                                                className={`${globalStyles.gblCWhite} ${globalStyles.globalTextV16}`}
                                            >
                                                User Email
                                            </Typography>
                                        </Grid>
                                        <Grid className={`${globalStyles.gblTextfieldItem}`}>
                                            <GlobalTextfield
                                                fullWidth
                                                value={""}
                                                size='small'
                                                placeholder='Enter User Email'
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid>
                                    <Grid container className={`${styles.memberHeadFieldContainer}`}>
                                        <Grid>
                                            <Typography
                                                component={"span"}
                                                className={`${globalStyles.gblCWhite} ${globalStyles.globalTextV16}`}
                                            >
                                                User Status
                                            </Typography>
                                        </Grid>
                                        <Grid className={`${globalStyles.gblTextfieldItem}`}>
                                            <GlobalTextfield
                                                fullWidth
                                                value={""}
                                                size='small'
                                                placeholder='Enter User Email'
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid>
                                    <Grid container className={`${styles.memberHeadFieldContainer}`}>
                                        <Grid>
                                            <Typography
                                                component={"span"}
                                                className={`${globalStyles.gblCWhite} ${globalStyles.globalTextV16}`}
                                            >
                                                Group
                                            </Typography>
                                        </Grid>
                                        <Grid className={`${globalStyles.gblTextfieldItem}`}>
                                            <GlobalTextfield
                                                fullWidth
                                                value={""}
                                                size='small'
                                                placeholder='Enter User Email'
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid>
                                    <Grid container className={`${styles.memberHeadFieldContainer}`}>
                                        <Grid >
                                            <Typography
                                                component={"span"}
                                                className={`${globalStyles.gblCWhite} ${globalStyles.globalTextV16}`}
                                            >
                                                Verify TD Ameritrade user
                                            </Typography>
                                        </Grid>
                                        <Grid className={`${globalStyles.gblTextfieldItem}`}>
                                            <GlobalTextfield
                                                fullWidth
                                                value={""}
                                                size='small'
                                                placeholder='Enter User Email'
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid size={{ xs: 12 }} className={`${globalStyles.gblCardBgWrapper}`}>
                            <Grid container sx={{ gap: "40px" }}>
                                <Grid className={`${globalStyles.gblTextfieldItem}`}>
                                    <Grid container>
                                        <Grid size={12} className={`${globalStyles.gblFieldSpcaing}`}>
                                            <Typography component={'span'} className={`${globalStyles.gblCWhite} ${globalStyles.globalTextV15}`}>
                                                Redtail Username
                                            </Typography>
                                        </Grid>
                                        <Grid size={12}>
                                            <GlobalTextfield
                                                placeholder='Enter User Name'
                                                size='medium'
                                                fullWidth
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid className={`${globalStyles.gblTextfieldItem}`}>
                                    <Grid container>
                                        <Grid size={12} className={`${globalStyles.gblFieldSpcaing}`}>
                                            <Typography component={'span'} className={`${globalStyles.gblCWhite} ${globalStyles.globalTextV15}`}>
                                                Password
                                            </Typography>
                                        </Grid>
                                        <Grid size={12}>
                                            <GlobalTextfield
                                                placeholder='Enter User Name'
                                                size='medium'
                                                fullWidth
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid className={`${globalStyles.gblTextfieldItem}`}>
                                    <Grid container>
                                        <Grid size={12} className={`${globalStyles.gblFieldSpcaing}`}>
                                            <Typography component={'span'} className={`${globalStyles.gblCWhite} ${globalStyles.globalTextV15}`}>
                                                TD Ameritrade username
                                            </Typography>
                                        </Grid>
                                        <Grid size={12}>
                                            <GlobalTextfield
                                                placeholder='Enter User Name'
                                                size='medium'
                                                fullWidth
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid className={`${globalStyles.gblTextfieldItem}`}>
                                    <Grid container>
                                        <Grid size={12} className={`${globalStyles.gblFieldSpcaing}`}>
                                            <Typography component={'span'} className={`${globalStyles.gblCWhite} ${globalStyles.globalTextV15}`}>
                                                Password
                                            </Typography>
                                        </Grid>
                                        <Grid size={12}>
                                            <GlobalTextfield
                                                placeholder='Enter User Name'
                                                size='medium'
                                                fullWidth
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid className={`${globalStyles.gblTextfieldItem}`}>
                                    <Grid container>
                                        <Grid size={12} className={`${globalStyles.gblFieldSpcaing}`}>
                                            <Typography component={'span'} className={`${globalStyles.gblCWhite} ${globalStyles.globalTextV15}`}>
                                                Repcode
                                            </Typography>
                                        </Grid>
                                        <Grid size={12}>
                                            <GlobalTextfield
                                                placeholder='Enter User Name'
                                                size='medium'
                                                fullWidth
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid size={{ xs: 12 }} sx={{ margin: "20px 0px" }}>
                            <Grid container >
                                <Grid size={{ xs: 12 }} sx={{ margin: "0px 0px 10px" }}>
                                    <Accordion
                                        expanded={expanded == 'panel1'}
                                        onChange={handleChange('panel1')}
                                        className={`${globalStyles.gblCardBgWrapper} ${globalStyles.gblAccordian}`}
                                        sx={{ padding: "0px !important" }}
                                    >
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon sx={{ color: "#ffffff" }} />}
                                            aria-controls="panel1-content"
                                            id="panel1-header"
                                            className={`${globalStyles.gblAccordianSummary}`}
                                        >
                                            <Typography className={`${globalStyles.gblCWhite} ${globalStyles.globalTextV16}`}>Subscribed Apps</Typography>
                                        </AccordionSummary>

                                        <AccordionDetails>
                                            <TableContainer>
                                                <Table size='small' className={`${globalStyles.gblTableStyles}`}>
                                                    <TableHead className={`${globalStyles.gblTableHeadStyles}`}>
                                                        <TableRow>
                                                            <TableCell
                                                                className={`${globalStyles.gblTableHeadCell}  ${globalStyles.gblLeftRadius}`}
                                                                sx={{ fontSize: "14px" }}
                                                            >
                                                                APP NAME
                                                            </TableCell>
                                                            <TableCell
                                                                className={`${globalStyles.gblTableHeadCell}`}
                                                                sx={{ fontSize: "14px" }}
                                                            >
                                                                ALLOW ACCESS
                                                            </TableCell>
                                                            <TableCell
                                                                sx={{ fontSize: "14px" }}
                                                            >
                                                                DEFAULT INSTALLED
                                                            </TableCell>
                                                            <TableCell
                                                                className={`${globalStyles.gblTableHeadCell} ${globalStyles.gblRightRadius}`}
                                                                sx={{ fontSize: "14px" }}
                                                            >
                                                                CURRENT APP STATUS
                                                            </TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {subscribedApps?.map((row: any, index: number) => (
                                                            <TableRow
                                                                key={index}
                                                                className={`${globalStyles.gblTableBodyRow}`}
                                                            >
                                                                <TableCell
                                                                    className={`${globalStyles.gblTableCellText} ${globalStyles.gblLeftRadius}`}
                                                                >
                                                                    {row.name}
                                                                </TableCell>

                                                                <TableCell
                                                                    className={`${globalStyles.gblTableCellText}`}
                                                                >
                                                                    <FormControlLabel
                                                                        control={
                                                                            <Checkbox
                                                                                size="small"
                                                                                checked={row.allowAccess}
                                                                                icon={<Icons iconName="checkbox" iconWidth="20px" iconHeight="20px" />}
                                                                                checkedIcon={<Icons iconName="checkedIcon" iconWidth="20px" iconHeight="20px" />}
                                                                                sx={{ padding: "0px 8px" }}
                                                                            />
                                                                        }
                                                                        label="Remember Me"
                                                                        className={styles.signinCheckboxLabel}
                                                                    />
                                                                </TableCell>

                                                                <TableCell
                                                                    className={`${globalStyles.gblTableCellText}`}
                                                                >
                                                                    <FormControlLabel
                                                                        control={
                                                                            <Checkbox
                                                                                size="small"
                                                                                checked={row.defaultInstalled}
                                                                                icon={<Icons iconName="checkbox" iconWidth="20px" iconHeight="20px" />}
                                                                                checkedIcon={<Icons iconName="checkedIcon" iconWidth="20px" iconHeight="20px" />}
                                                                                sx={{ padding: "0px 8px" }}
                                                                            />
                                                                        }
                                                                        label="Remember Me"
                                                                        className={styles.signinCheckboxLabel}
                                                                    />
                                                                </TableCell>

                                                                <TableCell className={`${globalStyles.gblTableCellText} ${globalStyles.gblRightRadius} ${styles.appStatusCell}`}>
                                                                    <GlobalSelect
                                                                        options={row.currentAppStatus.map((option: any) => ({ label: option.value, value: option.key }))}
                                                                        value={selectedValue}
                                                                        fullWidth
                                                                        size='small'
                                                                        onChange={(event: any) => { handleSelectChange(event) }}
                                                                        className={`${styles.subscribeSelectStyles}`}
                                                                    />
                                                                </TableCell>
                                                            </TableRow>
                                                        ))}
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </AccordionDetails>
                                    </Accordion>
                                </Grid>

                                <Grid size={{ xs: 12 }} sx={{ margin: "10px 0px" }}>
                                    <Accordion
                                        expanded={expanded == 'panel2'}
                                        onChange={handleChange('panel2')}
                                        className={`${globalStyles.gblCardBgWrapper} ${globalStyles.gblAccordian}`}
                                        sx={{ padding: "0px !important", }}
                                    >
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon sx={{ color: "#ffffff" }} />}
                                            aria-controls="panel1-content"
                                            id="panel1-header"
                                            className={`${globalStyles.gblAccordianSummary}`}
                                        >
                                            <Typography className={`${globalStyles.gblCWhite} ${globalStyles.globalTextV16}`}>Profile</Typography>
                                        </AccordionSummary>

                                        <AccordionDetails>
                                            <Grid container>
                                                <Grid size={{ xs: 12 }}>
                                                    <Grid container sx={{ columnGap: "25px", rowGap: "15px" }}>
                                                        <Grid className={`${globalStyles.gblTextfieldItem}`}>
                                                            <Grid container >
                                                                <Grid size={12} className={`${globalStyles.gblFieldSpcaing}`}>
                                                                    <Typography component={'span'} className={`${globalStyles.gblCWhite} ${globalStyles.globalTextV15}`}>
                                                                        Username
                                                                    </Typography>
                                                                </Grid>
                                                                <Grid size={12}>
                                                                    <GlobalTextfield
                                                                        autoComplete='off'
                                                                        placeholder='Enter User Name'
                                                                        size='medium'
                                                                        fullWidth
                                                                    />
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>

                                                        <Grid className={`${globalStyles.gblTextfieldItem}`}>
                                                            <Grid container>
                                                                <Grid size={12} className={`${globalStyles.gblFieldSpcaing}`}>
                                                                    <Typography component={'span'} className={`${globalStyles.gblCWhite} ${globalStyles.globalTextV15}`}>
                                                                        First Name
                                                                    </Typography>
                                                                </Grid>
                                                                <Grid size={12}>
                                                                    <GlobalTextfield
                                                                        autoComplete='off'
                                                                        placeholder='Enter First Name'
                                                                        size='medium'
                                                                        fullWidth
                                                                    />
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>

                                                        <Grid className={`${globalStyles.gblTextfieldItem}`}>
                                                            <Grid container>
                                                                <Grid size={12} className={`${globalStyles.gblFieldSpcaing}`}>
                                                                    <Typography component={'span'} className={`${globalStyles.gblCWhite} ${globalStyles.globalTextV15}`}>
                                                                        Last Name
                                                                    </Typography>
                                                                </Grid>
                                                                <Grid size={12}>
                                                                    <GlobalTextfield
                                                                        autoComplete='off'
                                                                        placeholder='Enter Last Name'
                                                                        size='medium'
                                                                        fullWidth
                                                                    />
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>

                                                        <Grid className={`${globalStyles.gblTextfieldItem}`}>
                                                            <Grid container>
                                                                <Grid size={12} className={`${globalStyles.gblFieldSpcaing}`}>
                                                                    <Typography component={'span'} className={`${globalStyles.gblCWhite} ${globalStyles.globalTextV15}`}>
                                                                        Agency Name
                                                                    </Typography>
                                                                </Grid>
                                                                <Grid size={12}>
                                                                    <GlobalTextfield
                                                                        autoComplete='off'
                                                                        placeholder='Enter Agency Name'
                                                                        size='medium'
                                                                        fullWidth
                                                                    />
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>

                                                        <Grid className={`${globalStyles.gblTextfieldItem}`}>
                                                            <Grid container>
                                                                <Grid size={12} className={`${globalStyles.gblFieldSpcaing}`}>
                                                                    <Typography component={'span'} className={`${globalStyles.gblCWhite} ${globalStyles.globalTextV15}`}>
                                                                        Phone
                                                                    </Typography>
                                                                </Grid>
                                                                <Grid size={12}>
                                                                    <GlobalTextfield
                                                                        autoComplete='off'
                                                                        placeholder='Enter Phone'
                                                                        size='medium'
                                                                        fullWidth
                                                                    />
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>

                                                        <Grid className={`${globalStyles.gblTextfieldItem}`}>
                                                            <Grid container>
                                                                <Grid size={12} className={`${globalStyles.gblFieldSpcaing}`}>
                                                                    <Typography component={'span'} className={`${globalStyles.gblCWhite} ${globalStyles.globalTextV15}`}>
                                                                        City
                                                                    </Typography>
                                                                </Grid>
                                                                <Grid size={12}>
                                                                    <GlobalTextfield
                                                                        autoComplete='off'
                                                                        placeholder='Enter City'
                                                                        size='medium'
                                                                        fullWidth
                                                                    />
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>

                                                        <Grid className={`${globalStyles.gblTextfieldItem}`}>
                                                            <Grid container>
                                                                <Grid size={12} className={`${globalStyles.gblFieldSpcaing}`}>
                                                                    <Typography component={'span'} className={`${globalStyles.gblCWhite} ${globalStyles.globalTextV15}`}>
                                                                        Zipcode
                                                                    </Typography>
                                                                </Grid>
                                                                <Grid size={12}>
                                                                    <GlobalTextfield
                                                                        autoComplete='off'
                                                                        placeholder='Enter Zipcode'
                                                                        size='medium'
                                                                        fullWidth
                                                                    />
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>

                                                        <Grid className={`${globalStyles.gblTextfieldItem}`}>
                                                            <Grid container>
                                                                <Grid size={12} className={`${globalStyles.gblFieldSpcaing}`}>
                                                                    <Typography component={'span'} className={`${globalStyles.gblCWhite} ${globalStyles.globalTextV15}`}>
                                                                        State
                                                                    </Typography>
                                                                </Grid>
                                                                <Grid size={12}>
                                                                    <GlobalAutocomplate
                                                                        placeholder='Enter Agency Name'
                                                                        size='small'
                                                                        fullWidth
                                                                        options={[
                                                                            {
                                                                                label: "Test",
                                                                                value: 1
                                                                            },
                                                                            {
                                                                                label: "Test2",
                                                                                value: 2
                                                                            },
                                                                        ]}
                                                                        value={null}
                                                                    />
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>

                                                        <Grid className={`${globalStyles.gblTextfieldItem}`}>
                                                            <Grid container>
                                                                <Grid size={12} className={`${globalStyles.gblFieldSpcaing}`}>
                                                                    <Typography component={'span'} className={`${globalStyles.gblCWhite} ${globalStyles.globalTextV15}`}>
                                                                        Address Line 1
                                                                    </Typography>
                                                                </Grid>
                                                                <Grid size={12}>
                                                                    <GlobalTextfield
                                                                        autoComplete='off'
                                                                        placeholder='Enter Zipcode'
                                                                        size='small'
                                                                        fullWidth
                                                                        multiline
                                                                        rows={3}
                                                                        stylingClassName={`${styles.multilineTextfield}`}
                                                                    />
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>

                                                        <Grid className={`${globalStyles.gblTextfieldItem}`}>
                                                            <Grid container>
                                                                <Grid size={12} className={`${globalStyles.gblFieldSpcaing}`}>
                                                                    <Typography component={'span'} className={`${globalStyles.gblCWhite} ${globalStyles.globalTextV15}`}>
                                                                        Address Line 2
                                                                    </Typography>
                                                                </Grid>
                                                                <Grid size={12}>
                                                                    <GlobalTextfield
                                                                        autoComplete='off'
                                                                        placeholder='Enter Zipcode'
                                                                        size='small'
                                                                        fullWidth
                                                                        multiline
                                                                        rows={3}
                                                                        stylingClassName={`${styles.multilineTextfield}`}
                                                                    />
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>

                                                        <Grid className={`${globalStyles.gblTextfieldItem}`}>
                                                            <Grid container sx={{ rowGap: "15px" }}>
                                                                <Grid size={12} className={`${globalStyles.gblFieldSpcaing}`}>
                                                                    <Typography component={'span'} className={`${globalStyles.gblCWhite} ${globalStyles.globalTextV15}`}>
                                                                        Affiliated with Broker Alliance
                                                                    </Typography>
                                                                </Grid>
                                                                <Grid size={12}>
                                                                    <FormControlLabel
                                                                        control={
                                                                            <Checkbox
                                                                                size="small"
                                                                                checked={true}
                                                                                icon={<Icons iconName="checkbox" iconWidth="20px" iconHeight="20px" />}
                                                                                checkedIcon={<Icons iconName="checkedIcon" iconWidth="20px" iconHeight="20px" />}
                                                                                sx={{ padding: "0px 8px" }}
                                                                            />
                                                                        }
                                                                        label=""
                                                                        className={styles.signinCheckboxLabel}
                                                                    />
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>

                                                        <Grid className={`${globalStyles.gblTextfieldItem}`}>
                                                            <Grid container>
                                                                <Grid size={12} className={`${globalStyles.gblFieldSpcaing}`}>
                                                                    <Typography component={'span'} className={`${globalStyles.gblCWhite} ${globalStyles.globalTextV15}`}>
                                                                        Affiliated with Brooklight Place Securities
                                                                    </Typography>
                                                                </Grid>
                                                                <Grid size={12}>
                                                                    <FormControlLabel
                                                                        control={
                                                                            <Checkbox
                                                                                size="small"
                                                                                checked={true}
                                                                                icon={<Icons iconName="checkbox" iconWidth="20px" iconHeight="20px" />}
                                                                                checkedIcon={<Icons iconName="checkedIcon" iconWidth="20px" iconHeight="20px" />}
                                                                                sx={{ padding: "0px 8px" }}
                                                                            />
                                                                        }
                                                                        label=""
                                                                        className={styles.signinCheckboxLabel}
                                                                    />
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>

                                                        <Grid className={`${globalStyles.gblTextfieldItem}`}>
                                                            <Grid container>
                                                                <Grid size={12} className={`${globalStyles.gblFieldSpcaing}`}>
                                                                    <Typography component={'span'} className={`${globalStyles.gblCWhite} ${globalStyles.globalTextV15}`}>
                                                                        How did you find us?
                                                                    </Typography>
                                                                </Grid>
                                                                <Grid size={12}>
                                                                    <GlobalTextfield
                                                                        autoComplete='off'
                                                                        placeholder='Enter Zipcode'
                                                                        size='small'
                                                                        fullWidth
                                                                        multiline
                                                                        rows={3}
                                                                        stylingClassName={`${styles.multilineTextfield}`}
                                                                    />
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </AccordionDetails>
                                    </Accordion>
                                </Grid>

                                <Grid size={{ xs: 12 }} sx={{ margin: "10px 0px" }}>
                                    <Accordion
                                        expanded={expanded == 'panel3'}
                                        onChange={handleChange('panel3')}
                                        className={`${globalStyles.gblCardBgWrapper} ${globalStyles.gblAccordian}`}
                                        sx={{ padding: "0px !important" }}
                                    >
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon sx={{ color: "#ffffff" }} />}
                                            aria-controls="panel1-content"
                                            id="panel1-header"
                                            className={`${globalStyles.gblAccordianSummary}`}
                                        >
                                            <Typography className={`${globalStyles.gblCWhite} ${globalStyles.globalTextV16}`}>Marketing Review</Typography>
                                        </AccordionSummary>

                                        <AccordionDetails>
                                            <TableContainer>
                                                <Table size='small' className={`${globalStyles.gblTableStyles}`}>
                                                    <TableHead className={`${globalStyles.gblTableHeadStyles}`}>
                                                        <TableRow>
                                                            <TableCell
                                                                className={`${globalStyles.gblTableHeadCell}  ${globalStyles.gblLeftRadius}`}
                                                                sx={{ fontSize: "14px" }}
                                                            >

                                                            </TableCell>
                                                            <TableCell
                                                                className={`${globalStyles.gblTableHeadCell}`}
                                                                sx={{ fontSize: "14px" }}
                                                            >
                                                                SUBJECT
                                                            </TableCell>
                                                            <TableCell
                                                                className={`${globalStyles.gblTableCellText} ${globalStyles.gblRightRadius}`}
                                                                sx={{ fontSize: "14px" }}
                                                            >
                                                                TIME
                                                            </TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {marketingReview?.map((row: any, index: number) => (
                                                            <TableRow
                                                                key={index}
                                                                className={`${globalStyles.gblTableBodyRow}`}
                                                            >
                                                                <TableCell
                                                                    className={`${globalStyles.gblTableCellText} ${globalStyles.gblLeftRadius}`}
                                                                    sx={{ width: "50px" }}
                                                                >
                                                                    <IconButton>
                                                                        <Icons iconName="addIcon" iconColor="#ffffff" />
                                                                    </IconButton>
                                                                </TableCell>

                                                                <TableCell
                                                                    className={`${globalStyles.gblTableCellText}`}
                                                                    sx={{ width: "120px" }}
                                                                >
                                                                    {row.subject}
                                                                </TableCell>

                                                                <TableCell
                                                                    className={`${globalStyles.gblTableCellText} ${globalStyles.gblRightRadius}`}
                                                                >
                                                                    {row.time}
                                                                </TableCell>

                                                            </TableRow>
                                                        ))}
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </AccordionDetails>
                                    </Accordion>
                                </Grid>

                                <Grid size={{ xs: 12 }} sx={{ margin: "10px 0px" }}>
                                    <Accordion
                                        expanded={expanded == 'panel4'}
                                        onChange={handleChange('panel4')}
                                        className={`${globalStyles.gblCardBgWrapper} ${globalStyles.gblAccordian}`}
                                        sx={{ padding: "0px !important" }}
                                    >
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon sx={{ color: "#ffffff" }} />}
                                            aria-controls="panel1-content"
                                            id="panel1-header"
                                            className={`${globalStyles.gblAccordianSummary}`}
                                        >
                                            <Typography className={`${globalStyles.gblCWhite} ${globalStyles.globalTextV16}`}>Activity Logs</Typography>
                                        </AccordionSummary>

                                        <AccordionDetails>
                                            <Grid container>
                                                <Grid size={{ xs: 12 }}>
                                                    <Grid container sx={{ gap: "15px" }}>
                                                        <Grid size={{ xs: 12 }} >
                                                            <Typography component={'span'} className={`${globalStyles.gblCWhite} ${globalStyles.globalTextV17}`}
                                                                sx={{ display: "inline-flex", maxWidth: "120px", width: "120px", fontWeight: '300' }}
                                                            >
                                                                Created at
                                                            </Typography>
                                                            <Typography component={'span'} className={`${globalStyles.gblCWhite} ${globalStyles.globalTextV17}`}
                                                                sx={{ fontWeight: "300" }}
                                                            >
                                                                09-1-2024 22:24:18:0
                                                            </Typography>
                                                        </Grid>
                                                        <Grid size={{ xs: 12 }}>
                                                            <Typography component={'span'} className={`${globalStyles.gblCWhite} ${globalStyles.globalTextV17}`}
                                                                sx={{ display: "inline-flex", maxWidth: "120px", width: "120px", fontWeight: '300' }}
                                                            >
                                                                Login IP
                                                            </Typography>
                                                            <Typography component={'span'} className={`${globalStyles.gblCWhite} ${globalStyles.globalTextV17}`}
                                                                sx={{ fontWeight: "300" }}
                                                            >
                                                                127.0.0.1
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </AccordionDetails>
                                    </Accordion>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}

export default ViewUsers