import React, { useEffect, useRef, useState } from 'react'
import globalStyles from '../../Styles/global.module.scss'
import styles from '../../Styles/ComponentStyles/MainPages/dashboard.module.scss'

import Grid from '@mui/material/Grid2';
import GlobalTabs from '../../Utils/Layout/GlobalTabs/GlobalTabs';
import { Divider, Typography } from '@mui/material';
import KeyIndices from '../KeyIndices/KeyIndices';
import CanvasJSReact from '../../Lib/canvasjs-stockchart-1.10.16/canvasjs.stock.react'

const CanvasJSChart = CanvasJSReact.CanvasJSChart;

const KeyIndicesDialog = (props: any) => {

    const { keyIndicesTabs, keyIndicesCurrentTab, handleKeyIndicesTabChange, keyIndicesData } = props

    const [ohlcData, setOhlcData] = useState<any[]>([]);
    const [isLoaded, setIsLoaded] = useState(false);
    const chartRef = useRef<any>(null);

    useEffect(() => {
        fetch('https://canvasjs.com/data/docs/btcusd2018.json')
            .then((res) => res.json())
            .then((data) => {
                const dps = data.map((item: any) => ({
                    x: new Date(item.date),
                    y: [Number(item.open), Number(item.high), Number(item.low), Number(item.close)],
                }));
                setOhlcData(dps);
                setIsLoaded(true);
            });
    }, []);

    const calculateTrendLine = () => {
        let a, b, c, d, e, slope, yIntercept;
        let chart = chartRef.current;
        let xSum = 0, ySum = 0, xySum = 0, xSquare = 0, dataLength = chart.data[0].dataPoints.length;

        for (let i = 0; i < dataLength; i++) {
            xySum += (chart.data[0].dataPoints[i].x.getTime() * chart.data[0].dataPoints[i].y[3]);
        }
        a = xySum * dataLength;

        for (let i = 0; i < dataLength; i++) {
            xSum += chart.data[0].dataPoints[i].x.getTime();
            ySum += chart.data[0].dataPoints[i].y[3];
        }
        b = xSum * ySum;

        for (let i = 0; i < dataLength; i++) {
            xSquare += Math.pow(chart.data[0].dataPoints[i].x.getTime(), 2);
        }
        c = dataLength * xSquare;

        d = Math.pow(xSum, 2);
        slope = (a - b) / (c - d);
        e = slope * xSum;
        yIntercept = (ySum - e) / dataLength;

        const startPoint = getTrendLinePoint(chart.data[0].dataPoints[0].x, slope, yIntercept);
        const endPoint = getTrendLinePoint(chart.data[0].dataPoints[dataLength - 1].x, slope, yIntercept);

        chart.addTo("data", {
            type: "line",
            markerSize: 0,
            name: "Trend",
            showInLegend: true,
            color: "#E64A19",
            yValueFormatString: "$#,###.00",
            dataPoints: [startPoint, endPoint],
        });
    };

    const getTrendLinePoint = (x: Date, slope: number, intercept: number) => {
        return { x, y: slope * x.getTime() + intercept };
    };

    useEffect(() => {
        if (isLoaded && chartRef.current) {
            calculateTrendLine();
        }
    }, [isLoaded]);

    const options = {
        theme: "light2",
        zoomEnabled: true,
        title: {
            text: "",
        },
        axisY: {
            visible: false,   // Hide the Y-axis completely
            lineThickness: 0, // Remove the line
            tickThickness: 0, // Remove the ticks
            gridThickness: 0, // Remove grid lines
        },
        axisX: {
            valueFormatString: "MMM",
        },
        legend: {
            verticalAlign: "bottom",
            cursor: "pointer",
            itemclick: function (e: any) {
                if (typeof e.dataSeries.visible === "undefined" || e.dataSeries.visible) {
                    e.dataSeries.visible = false;
                } else {
                    e.dataSeries.visible = true;
                }
                e.chart.render();
            },
        },
        toolTip: {
            shared: true,
        },
        data: [
            {
                type: "ohlc",
                showInLegend: true,
                name: "Bitcoin Price",
                yValueFormatString: "$#,###.00",
                xValueType: "dateTime",
                dataPoints: ohlcData,
            },
        ],
    };







    return (
        <>
            <Grid container>
                <Grid size={{ xs: 12 }}>
                    <Grid container>
                        <Grid size={{ xs: 12 }} className={`${globalStyles.globalTabMainItem}`}>
                            <GlobalTabs
                                tabs={keyIndicesTabs}
                                currentTab={keyIndicesCurrentTab}
                                onChange={handleKeyIndicesTabChange}
                                sxTabStyle={{
                                    textTransform: 'none',
                                    color: '#7E7E7E',
                                    marginRight: '20px',
                                    fontWeight: 'normal',
                                    fontSize: '1rem',
                                    '&.Mui-selected': {
                                        color: '#ffffff',
                                        fontWeight: 'bold',
                                    }
                                }}
                            />
                        </Grid>

                        <Grid size={{ xs: 12 }}>
                            <CanvasJSChart options={options} onRef={(ref: any) => (chartRef.current = ref)} />
                        </Grid>

                        <Grid size={{ xs: 12 }} >
                            <Grid container>
                                <KeyIndices
                                    keyIndicesData={keyIndicesData}
                                    flagVisibleDivider={false}
                                />
                            </Grid>
                        </Grid>


                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}

export default KeyIndicesDialog