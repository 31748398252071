import React, { useState } from 'react'
import globalStyles from '../../Styles/global.module.scss'

import Grid from '@mui/material/Grid2';
import { Outlet, useNavigate } from 'react-router-dom';
import GlobalTabs from '../../Utils/Layout/GlobalTabs/GlobalTabs';
import { members } from '../../Utils/Constant/Constant';
import AppNavbar from '../../Components/AppNavbar/AppNavbar';


const Member = () => {

    const [currentTab, setCurrentTab] = useState<any>("Users");
    const navigate = useNavigate()
    const page = window.location.pathname

    const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
        const selectedTab = members.find((tab) => tab.value == newValue);
        if (selectedTab) {
            navigate(selectedTab.navigation);
            setCurrentTab(newValue);
        }
    };


    return (
        <>
            <AppNavbar navbarHeadingName={"Member"} />
            <Grid
                container
                className={`${page !== "/member/viewUsers" ? `${globalStyles.gblCardBgWrapper} ${globalStyles.gblWrapperOuterSpacing}` : ''}`}
            >
                {page !== "/member/viewUsers" &&
                    <Grid size={{ xs: 12 }} sx={{ margin: "0px 0px 10px" }}>
                        <GlobalTabs
                            tabs={members}
                            currentTab={currentTab}
                            onChange={(event: React.SyntheticEvent, newValue: string) => handleTabChange(event, newValue)}
                            sxTabStyle={{
                                textTransform: 'none',
                                color: '#7E7E7E',
                                marginRight: '20px',
                                fontWeight: 'normal',
                                fontSize: '1rem',
                                '&.Mui-selected': {
                                    color: '#ffffff',
                                    fontWeight: 'bold',
                                }
                            }}
                        />
                    </Grid>
                }

                <Grid size={{ xs: 12 }}>
                    <Outlet />
                </Grid>
            </Grid>
        </>
    )
}


export default Member