
import Grid from '@mui/material/Grid2';
import React, { useContext, useEffect } from 'react'
import globalStyles from '../Styles/global.module.scss';
import SideNavigationPanel from '../Components/SideNavigationPanel/SideNavigationPanel';
import { Outlet } from 'react-router-dom';
import { GlobalUserContext, GlobalUserContextPayload } from '../Context/GlobalUserDetailContext';
import AdminAppNavbar from '../Components/AdminAppNavbar/AdminAppNavbar';


const MainComponent = () => {

    const { drawerOpen,
        setDrawerOpen,
        userProfileDetails,
        currentSelectedTab,
        isAdminNavbarVisible,
        userRole
    } = useContext(GlobalUserContext) as GlobalUserContextPayload

    const isProfileAdmin = userProfileDetails?.user?.flagSuperAdmin

    return (
        <>
            <Grid container className={`${globalStyles.mainContainer}`}>
                {isProfileAdmin == false &&
                    <Grid size={{ xs: 12 }} className={`${globalStyles.appBarNav}`}>
                        <AdminAppNavbar />
                    </Grid>
                }
                <Grid size={{ xs: 12 }} className={`${isProfileAdmin == false ? globalStyles.fullHeightForAdminNav : globalStyles.fullHeightStyle} `}>
                    <Grid container className={`${globalStyles.fullHeightStyle}`}>
                        <Grid
                            className={`${globalStyles.fullHeightStyle}`}
                            sx={{ width: `${drawerOpen ? "250px" : '80px'}` }}
                        >
                            <SideNavigationPanel />
                        </Grid>
                        <Grid
                            className={`${globalStyles.fullHeightStyle} ${globalStyles.globalContentBgWrapper}`}
                            sx={{
                                width: `${drawerOpen ? "calc(100% - 250px) !important" : "calc(100% - 80px) !important"}`,
                                maxWidth: `${drawerOpen ? "calc(100% - 250px) !important" : "calc(100% - 80px) !important"}`,
                                overflow: 'auto'
                            }}
                        >
                            <Outlet />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}

export default MainComponent