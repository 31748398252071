import React from 'react'
import styles from '../../Styles/ComponentStyles/GlobalComponentStyles/Appnavbar.module.scss'

import Grid from '@mui/material/Grid2';
import { Divider, Typography } from '@mui/material'

interface navbarProps {
    navbarHeadingName: string;
}

const AppNavbar = (props: navbarProps) => {

    const { navbarHeadingName } = props

    return (
        <>
            <Grid sx={{ width: "100%" }} container className={`${styles.navbarMainContainer}`}>
                <Grid size={{ xs: 12 }} className={`${styles.navbarMainItem}`}>
                    <Grid container sx={{ gap: "15px" }}>
                        <Grid size={{ xs: 12 }}>
                            <Typography
                                component={'span'}
                                className={`${styles.navbarHeadText}`}
                            >
                                {navbarHeadingName}
                            </Typography>
                        </Grid>
                        <Grid size={{ xs: 12 }}>
                            <Divider sx={{ borderColor: '#FFFFFF' }} />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}

export default AppNavbar