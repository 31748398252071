import React from 'react'
import Grid from '@mui/material/Grid2';
import pdf from '../../Assets/Dashboard/pdf.png'
import { Typography } from '@mui/material';
import styles from '../../Styles/ComponentStyles/MainPages/dashboard.module.scss'

interface pdfCardsProps {
    pdfMainName: string,
    pdfSubName: string
}


const PdfCards = (props: pdfCardsProps) => {

    const { pdfMainName, pdfSubName } = props

    return (
        <>
            <Grid container>
                <Grid size={{ xs: 12 }}>
                    <Grid container>
                        <Grid size={{ xs: 12 }}>
                            <img src={pdf} style={{ width: "50px", height: 'auto' }} alt="" />
                        </Grid>
                        <Grid size={{ xs: 12 }} className={`${styles.pdfNameItem}`} sx={{ margin: "5px 0px 5px" }}>
                            <Typography component={'span'}>
                                {pdfMainName}
                            </Typography>
                        </Grid>
                        <Grid size={{ xs: 12 }} className={`${styles.pdfSubNameItem}`}>
                            <Typography component={'span'}>
                                {pdfSubName}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}

export default PdfCards