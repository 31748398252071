import React, { useState } from 'react'
import globalStyles from "../../../Styles/global.module.scss"
import styles from "../../../Styles/ComponentStyles/MainPages/Member.module.scss"


import Grid from '@mui/material/Grid2';
import { Outlet, useNavigate } from 'react-router-dom';
import GlobalTabs from '../../../Utils/Layout/GlobalTabs/GlobalTabs';
import { macSampleData, members } from '../../../Utils/Constant/Constant';
import { Button, Checkbox, FormControlLabel, InputAdornment, Pagination, PaginationItem, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { Search } from '@mui/icons-material';
import GlobalTextfield from '../../../Utils/Layout/GlobalTextfield/GlobalTextfield';
import Icons from '../../../Utils/Layout/Icons/Icons';
import { MemberContext, MemberContextPayload } from '../../../Context/MemberDetailsContext';


const MacApp = () => {

    const [page, setPage] = useState(1);
    const rowsPerPage = 12;
    const paginatedData = macSampleData.slice((page - 1) * rowsPerPage, page * rowsPerPage);
    const totalPages = Math.ceil(macSampleData.length / rowsPerPage);
    const handlePageChange = (_event: React.ChangeEvent<unknown>, value: number) => {
        setPage(value);
    };

    return (
        <>
            <Grid container>
                <Grid size={{ xs: 12 }}>
                    <Grid container>
                        <Grid size={{ xs: 12 }}>
                            <Grid container sx={{ alignItems: "center" }}>
                                <Grid size={{ xs: 6 }}>
                                    <Typography component={'span'} className={`${globalStyles.gblCWhite} ${globalStyles.globalTextV16}`}>
                                        Show entries
                                    </Typography>
                                </Grid>
                                <Grid size={{ xs: 6 }}>
                                    <Grid container className={`${styles.memberSearchbarContainer}`}>
                                        <Grid className={`${styles.memberSearchbarItem}`}>
                                            <GlobalTextfield
                                                fullWidth
                                                stylingClassName={`${styles.memberSearchbar}`}
                                                size='small'
                                                autoComplete='off'
                                                placeholder="Search"
                                                // onChange={(e) => {
                                                //     handleSearch(e.target.value)
                                                // }}
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            <Icons iconName="searchIcon" iconColor="#b6b6b6" iconWidth="18px" iconHeight="18px" />
                                                        </InputAdornment>
                                                    ),
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid size={{ xs: 12 }} sx={{ margin: "20px 0px 0px" }}>
                            <TableContainer>
                                <Table size='small' className={`${globalStyles.gblTableStyles}`}>
                                    <TableHead className={`${globalStyles.gblTableHeadStyles}`}>
                                        <TableRow>
                                            <TableCell
                                                className={`${globalStyles.gblTableHeadCell}  ${globalStyles.gblLeftRadius}`}
                                                sx={{ fontSize: "14px" }}
                                            >
                                                APP NAME
                                            </TableCell>
                                            <TableCell
                                                className={`${globalStyles.gblTableHeadCell}`}
                                                sx={{ fontSize: "14px" }}
                                            >
                                                ALLOW ACCESS
                                            </TableCell>
                                            <TableCell
                                                className={`${globalStyles.gblTableHeadCell} ${globalStyles.gblRightRadius}`}
                                                sx={{ fontSize: "14px" }}
                                            >
                                                DEFAULT INSTALLED
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {macSampleData?.map((row: any, index: number) => (
                                            <TableRow
                                                key={index}
                                                className={`${globalStyles.gblTableBodyRow}`}
                                            >
                                                <TableCell
                                                    className={`${globalStyles.gblTableCellText} ${globalStyles.gblLeftRadius}`}
                                                >
                                                    {row.name}
                                                </TableCell>

                                                <TableCell
                                                    className={`${globalStyles.gblTableCellText}`}
                                                >
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                size="small"
                                                                checked={row.allowAccess}
                                                                icon={<Icons iconName="checkbox" iconWidth="20px" iconHeight="20px" />}
                                                                checkedIcon={<Icons iconName="checkedIcon" iconWidth="20px" iconHeight="20px" />}
                                                                sx={{ padding: "0px 8px" }}
                                                            />
                                                        }
                                                        label="Remember Me"
                                                        className={styles.signinCheckboxLabel}
                                                    />
                                                </TableCell>

                                                <TableCell
                                                    className={`${globalStyles.gblTableCellText} ${globalStyles.gblRightRadius}`}
                                                >
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                size="small"
                                                                checked={row.defaultInstalled}
                                                                icon={<Icons iconName="checkbox" iconWidth="20px" iconHeight="20px" />}
                                                                checkedIcon={<Icons iconName="checkedIcon" iconWidth="20px" iconHeight="20px" />}
                                                                sx={{ padding: "0px 8px" }}
                                                            />
                                                        }
                                                        label="Remember Me"
                                                        className={styles.signinCheckboxLabel}
                                                    />
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}

export default MacApp