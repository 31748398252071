import axios, { AxiosError } from 'axios';
import { forgotPasswordPayloadModel, loginPayloadModel, userLogoutResponseDataModel, UserRegistrationPayloadModel } from '../models/axiosModels';
import { getRevenuePayloadData } from '../models/dashboardAxiosModels';


axios.defaults.withCredentials = true;
axios.defaults.baseURL = process.env.REACT_APP_API_CALL_URL;
// axios.defaults.baseURL = "http://192.168.1.2:3000";


let apiBuffer: Array<{ name: string, controller: AbortController }> = [];

const responseBody = (response: any) => {
    if (response?.Title === undefined)
        return response.data
}

const Error = (err: AxiosError) => {
    // @ts-ignore
    if (err?.response !== null && err?.response !== undefined) {
        console.log("response.response.data.error--", err?.response?.data)
    }
    return err;
}

const requests = {

    get: async (url: string) => {
        try {
            // @ts-ignore
            const controller = new AbortController();
            // @ts-ignore
            apiBuffer.push({ name: url, controller: controller })
            const response = await axios.get(url,
                {
                    headers: {
                        "Content-Type": "application/json"
                    },
                    signal: controller.signal
                });
            // @ts-ignore
            if (response?.flagStatus === false && response?.error !== "" && response?.error !== null && response?.error !== undefined) {
                alert("Something went wrong.!");
            }
            else {
                return responseBody(response);
            }
        }
        catch (err: any) {
            return Error(err);
        }
    },

    fileUploadPost: async (url: string, body: any) => {
        try {
            const response = await axios.post(url, body, {
                headers: {
                    "Content-Type": "multipart/form-data"
                }
            });
            return responseBody(response);
        }
        catch (err: any) {
            return Error(err);
        }
    },

    post: async (url: string, body: any, onAbort?: () => void) => {
        try {
            // @ts-ignore
            const controller = new AbortController();
            // @ts-ignore
            apiBuffer.push({ name: url, controller: controller })
            // @ts-ignore
            const response = await axios.post(url, body, {
                headers: {
                    "Content-Type": "application/json"
                },
                signal: controller.signal
            });
            return responseBody(response);
        }
        catch (err: any) {
            if (axios.isCancel(err)) {
                console.log("Request was canceled", err);
                // @ts-ignore
                apiBuffer = apiBuffer.filter((api: any) => api.name !== url)
                if (onAbort) console.log("onabort called");
            } else {
                console.log("not aborted", err)
            }
            return Error(err);
        }
    },

    put: async (url: string, body: any) => {
        try {
            const response = await axios.put(url, body, {
                headers: {
                    "Content-Type": "application/json"
                }
            });
            return responseBody(response);
        } catch (err: any) {
            return Error(err);
        }
    },

    delete: async (url: string) => {
        try {
            const response = await axios.delete(url, {
                headers: {
                    "Content-Type": "application/json"
                }
            });
            return responseBody(response);
        } catch (err: any) {
            return Error(err);
        }
    }
};

const signInAPI = {
    post: (payload: loginPayloadModel) => requests.post("api/user/login", payload)
}

const userDetailAPI = {
    get: () => requests.get("api/user/get-user-detail")
}

const userRegistrationAPI = {
    post: (payload: UserRegistrationPayloadModel) => requests.post("api/user/register", payload)
}

const logoutAPI = {
    get: () => requests.get("api/user/logout")
}

const forgotPasswordAPI = {
    post: (payload: forgotPasswordPayloadModel) => requests.post("api/user/forgot-password", payload)
}

const getUserDashboardAPI = {
    get: () => requests.get("api/dashboard")
}

const getMarketingViewDetailsAPI = {
    get: () => requests.get("api/dashboard/marketing-view-details")
}

const getUserMarketingViewAPI = {
    get: () => requests.get("api/dashboard/marketing-view")
}

const getUserMarketingPortfolioAPI = {
    get: () => requests.get("api/dashboard/marketing-portfolio")
}

const getRevenueDataAPI = {
    post: (payload: getRevenuePayloadData) => requests.post("api/revenue", payload)
}

const revenueGeneratePdfAPI = {
    post: (payload: getRevenuePayloadData) => requests.post("api/revenue/generate-pdf", payload)
}

const agent = {
    signInAPI,
    userDetailAPI,
    userRegistrationAPI,
    logoutAPI,
    forgotPasswordAPI,
    getUserDashboardAPI,
    getMarketingViewDetailsAPI,
    getUserMarketingViewAPI,
    getUserMarketingPortfolioAPI,
    getRevenueDataAPI,
    revenueGeneratePdfAPI
};

export default agent;