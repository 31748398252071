import React from 'react'
import { Tabs, Tab } from '@mui/material';

interface TabConfig {
    label: string;
    value: string; // Identifier for the tab
}

interface GlobalTabsProps {
    tabs?: TabConfig[];
    currentTab: string;
    onChange: (event: React.SyntheticEvent, newValue: string) => void;
    sxTabStyle: any;
}

const GlobalTabs = (props: GlobalTabsProps) => {

    const { tabs, currentTab, onChange, sxTabStyle } = props

    const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
        onChange(event, newValue);
    };

    return (
        <>
            <Tabs
                value={currentTab}
                onChange={handleTabChange}
                sx={{
                    '.MuiTabs-indicator': {
                        backgroundColor: '#ffffff',
                        height: "4px",
                        borderTopLeftRadius: "40px",
                        borderTopRightRadius: "40px"
                    }
                }}
            >
                {tabs && tabs?.map(tab => (
                    <Tab
                        key={tab.value}
                        label={tab.label}
                        value={tab.value}
                        sx={sxTabStyle}
                    />
                ))}
            </Tabs>
        </>
    )
}

export default GlobalTabs



{/* <Tabs
    value={value}
    onChange={handleChange}
    aria-label="styled tabs example"
    sx={{
        '.MuiTabs-indicator': {
            backgroundColor: '#ffffff',
            height: "4px",
            borderTopLeftRadius: "40px",
            borderTopRightRadius: "40px"
        }
    }}
    >
    <Tab
        label="Information"
        sx={{
            textTransform: 'none',
            color: '#7E7E7E',
            marginRight: '20px',
            fontWeight: 'normal',
            fontSize: '1rem',
            '&.Mui-selected': {
                color: '#ffffff',
                fontWeight: 'bold',
            },
        }}
    />
    <Tab
        label="Other Apps"
        sx={{
            textTransform: 'none',
            color: '#7E7E7E',
            fontWeight: 'normal',
            fontSize: '1rem',
            '&.Mui-selected': {
                color: '#ffffff',
                fontWeight: 'bold',
            },
        }}
    />
    </Tabs> */}