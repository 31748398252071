import React, { useState } from "react";
import { ChildrenProps } from "../models/componentModel";
import { userData } from "../Utils/Constant/Constant";

const MemberContext = React.createContext<MemberContextPayload | undefined>(
    undefined
);

export interface MemberContextPayload {

    memberUserDetails: any;
    setMemberUserDetails: React.Dispatch<React.SetStateAction<any>>

}

const MemberProvider = ({ children }: ChildrenProps) => {

    const [memberUserDetails, setMemberUserDetails] = useState<any>(userData)


    const payload = {
        memberUserDetails,
        setMemberUserDetails,
    };

    return (
        <MemberContext.Provider value={payload}>{children}</MemberContext.Provider>
    );
};

export { MemberContext, MemberProvider };
