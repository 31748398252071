import React, { useContext, useEffect, useRef, useState } from 'react'
import loginStyles from "../../Styles/ComponentStyles/MainPages/Signin.module.scss"
import styles from "../../Styles/ComponentStyles/MainPages/Reset.module.scss"
import globalStyles from "../../Styles/global.module.scss"

import Grid from '@mui/material/Grid2';
import { IconButton, InputAdornment, Typography } from '@mui/material';
import ellipsis from "../../Assets/General/Ellipse.png"
import logo from "../../Assets/Logos/login-mac-logo.png"
import GlobalTextfield from '../../Utils/Layout/GlobalTextfield/GlobalTextfield';
import { GlobalUserContext, GlobalUserContextPayload } from '../../Context/GlobalUserDetailContext';
import GlobalButton from '../../Utils/Layout/GlobalButton/GlobalButton';
import OtpInput from 'react18-input-otp';
import GlobalSymbols from '../../Utils/Layout/GlobalSymbols/GlobalSymbols';
import { Margin, Visibility, VisibilityOff } from '@mui/icons-material';
import { useSnackbar } from '../../Utils/Layout/GlobalSnackbar/GlobalSnackbar';
import { useNavigate } from 'react-router-dom';



const ResetPasword = () => {
    const iframeRef = useRef<HTMLIFrameElement | null>(null);
    const videoUrl = "https://player.vimeo.com/video/138115423?title=0&byline=0&portrait=0&controls=1";


    const {
        forgotPasswordDetails,
        setForgotPasswordDetails,
        setFlagLoader
    } = useContext(GlobalUserContext) as GlobalUserContextPayload

    const { showSnackbar } = useSnackbar();
    const navigate = useNavigate()
    const [email, setEmail] = useState('');
    const [showOtpInput, setShowOtpInput] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [emailError, setEmailError] = useState(false);
    const [errors, setErrors] = useState({
        password: "",
        confirm_password: "",
    });
    const [timer, setTimer] = useState(120); // 2 minutes in seconds
    const [isTimerActive, setIsTimerActive] = useState(false);

    useEffect(() => {
        let intervalId: any;

        if (isTimerActive && timer > 0) {
            intervalId = setInterval(() => {
                setTimer((prevTime) => prevTime - 1);
            }, 1000);
        } else if (timer <= 0) {
            if (intervalId) clearInterval(intervalId);
        }

        return () => {
            if (intervalId) clearInterval(intervalId);
        };
    }, [isTimerActive, timer]);


    const handleSendOtp = () => {
        setFlagLoader(true)
        if (validateEmail(email)) {
            setShowOtpInput(true);
            setIsTimerActive(true);
            setTimer(120);
        } else {
            setEmailError(true)
        }
        setFlagLoader(false)
    };

    const formatTime = (time: number) => {
        const minutes = Math.floor(time / 60);
        const seconds = time % 60;
        return `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
    };

    // Validate email (basic example, you can make it more robust)
    const validateEmail = (email: string) => {
        const regex = /\S+@\S+\.\S+/;
        return regex.test(email);
    };

    // Handle OTP change
    const handleOtpChange = (value: string) => {
        setForgotPasswordDetails({
            ...forgotPasswordDetails,
            otp: value
        });
    };

    const handleOtpSubmit = () => {

        const newErrors = validateForm();
        setErrors(newErrors);

        // If there are errors, stop submission
        if (Object.values(newErrors)?.some((error) => error !== "")) {
            return;
        }

        if (forgotPasswordDetails.otp === '123456') {
            showSnackbar('OTP Verified Successfully', "success");
            setTimeout(() => {
                navigate("/")
            }, 1000);
        } else {
            showSnackbar('Invalid OTP', 'error');
        }
    };

    const validateForm = () => {
        const newErrors: any = {};

        // Validate password
        if (!forgotPasswordDetails.password) {
            newErrors.password = "Password is required.";
        } else if (forgotPasswordDetails.password.length < 6) {
            newErrors.password = "Password must be at least 6 characters long.";
        }

        // Validate confirm password
        if (forgotPasswordDetails.confirm_password !== forgotPasswordDetails.password) {
            newErrors.confirm_password = "Confirm password must match the password.";
        }

        return newErrors;
    };

    const handleBlur = (field: string) => {
        const newErrors = validateForm();
        setErrors({
            ...errors,
            [field]: newErrors[field] || "",
        });
    };


    return (
        <>
            <Grid container>
                <Grid size={{ xs: 12 }}>
                    <Grid container className={`${globalStyles.fullHeight} ${loginStyles.signinMainContainer}`}>
                        <Grid size={{ xs: 5.8 }}>
                            <Grid container sx={{ alignItems: 'center', height: "100%" }}>
                                <Grid className={`${loginStyles.signinFormItem}`}>
                                    <Grid size={{ xs: 12 }}>
                                        <Grid container>
                                            <Grid size={{ xs: 12 }}>
                                                <Typography component={'span'} className={`${globalStyles.globalTextV35} ${globalStyles.gblC171923}`} sx={{ fontWeight: "700" }}>
                                                    Recover your password
                                                </Typography>
                                            </Grid>
                                            <Grid size={{ xs: 12 }}>
                                                {showOtpInput ?
                                                    <>
                                                        <Typography component={'span'} className={`${globalStyles.globalTextV18} ${globalStyles.gblC171923}`} sx={{ fontWeight: "600 !important" }}>
                                                            Verify the code and reset your password so you can use login credentials from BA MAC, Retirement ACE or LifeBrain.io
                                                        </Typography>
                                                    </>
                                                    :
                                                    <>
                                                        <Typography component={'span'} className={`${globalStyles.globalTextV18} ${globalStyles.gblC171923}`} sx={{ fontWeight: "700 !important" }}>
                                                            Enter the email that&nbsp;
                                                            <Typography component={'span'} className={`${globalStyles.globalTextV18} ${globalStyles.gblC171923}`} sx={{ fontWeight: "400 !important" }}>
                                                                you used when you signed up to recover your password. You will receive a&nbsp;
                                                                <Typography component={'span'} className={`${globalStyles.globalTextV18}`} sx={{ fontWeight: "700 !important" }}>password reset link.</Typography>
                                                            </Typography>
                                                        </Typography>
                                                    </>
                                                }
                                            </Grid>
                                        </Grid>
                                    </Grid>


                                    {showOtpInput ?
                                        (
                                            <Grid size={{ xs: 12 }} sx={{ margin: "40px 0px" }}>
                                                <Grid container>
                                                    <Grid size={{ xs: 12 }} sx={{ margin: '0px 0px 10px' }}>
                                                        <Grid container>
                                                            <Grid size={{ xs: 6 }}>
                                                                <Grid container>
                                                                    <Grid size={{ xs: 12 }} className={`${globalStyles.globalTextV20} ${globalStyles.gbl1A2537}`}>
                                                                        Verification Code
                                                                    </Grid>
                                                                    <Grid size={{ xs: 12 }} sx={{ color: "#565F6B", mt: "3px" }} className={`${globalStyles.globalTextV14}`}>
                                                                        Sent to&nbsp;{email ? email : "xyz@gmail.com"}
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                            {showOtpInput && (
                                                                <Grid size={{ xs: 6 }}
                                                                    className={`${globalStyles.globalTextV14}  ${globalStyles.gblC1A2537}`}
                                                                    sx={{ textAlign: "end" }}
                                                                >
                                                                    Time Remaining: {formatTime(timer)}
                                                                </Grid>
                                                            )}
                                                        </Grid>
                                                    </Grid>
                                                    <Grid size={{ xs: 12 }} sx={{ margin: "20px 0px 10px" }}>
                                                        <OtpInput
                                                            value={forgotPasswordDetails.otp}
                                                            onChange={handleOtpChange}
                                                            numInputs={6}  // Number of OTP fields
                                                            separator={<span className={styles.separator}>-</span>}
                                                            isInputNum={true}  // Only allow numbers
                                                            inputStyle={{
                                                                width: '50px',
                                                                height: '50px',
                                                                fontSize: '20px',
                                                                borderRadius: '8px', // Rounded edges
                                                                border: '1px solid #ccc',
                                                                textAlign: 'center',
                                                                margin: '0 5px',
                                                            }}
                                                            focusStyle={{
                                                                border: '2px solid #3f51b5', // Blue focus border
                                                            }}
                                                            containerStyle={{
                                                                display: 'flex',
                                                                justifyContent: 'center',
                                                                alignItems: 'center',
                                                                gap: '10px',  // Space between OTP inputs
                                                            }}
                                                        />
                                                    </Grid>
                                                    <Grid size={{ xs: 12 }}>
                                                        <Grid container>
                                                            <Grid size={{ xs: 12 }} sx={{ margin: "20px 0px 10px" }}>
                                                                <Grid container>
                                                                    <Grid size={{ xs: 12 }} sx={{ mb: "5px" }}>
                                                                        <Typography component={'span'} className={`${globalStyles.gblC171923} ${globalStyles.globalTextV16}`}>
                                                                            Password
                                                                        </Typography>
                                                                    </Grid>
                                                                    <Grid size={{ xs: 12 }}>
                                                                        <GlobalTextfield
                                                                            fullWidth
                                                                            placeholder='Enter Password'
                                                                            stylingClassName={`${globalStyles.globalFieldStyles}`}
                                                                            size='medium'
                                                                            autoComplete='off'
                                                                            type={showPassword ? 'text' : 'password'}
                                                                            onBlur={() => handleBlur("password")}
                                                                            error={Boolean(errors.password)}
                                                                            helperText={errors.password}
                                                                            value={forgotPasswordDetails.password}
                                                                            onChange={(e) => {
                                                                                setForgotPasswordDetails({
                                                                                    ...forgotPasswordDetails,
                                                                                    password: e.target.value
                                                                                })
                                                                            }}
                                                                            InputProps={{
                                                                                endAdornment: (
                                                                                    <InputAdornment position="start" sx={{ margin: '0px', borderLeft: "1px solid #CFD9E0" }}>
                                                                                        <IconButton
                                                                                            edge="start"
                                                                                            size='small'
                                                                                            onClick={() => { setShowPassword(!showPassword) }}
                                                                                            sx={{
                                                                                                margin: "0px 10px 0px 5px",
                                                                                            }}
                                                                                        >
                                                                                            {showPassword ?
                                                                                                <VisibilityOff
                                                                                                    sx={{ fontSize: "23px", color: "#718096" }}
                                                                                                /> :
                                                                                                <Visibility
                                                                                                    sx={{ fontSize: "23px", color: "#718096" }}
                                                                                                />
                                                                                            }
                                                                                        </IconButton>
                                                                                    </InputAdornment>
                                                                                ),
                                                                            }}
                                                                        />
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>

                                                            <Grid size={{ xs: 12 }} sx={{ margin: "20px 0px 10px" }}>
                                                                <Grid container>
                                                                    <Grid size={{ xs: 12 }} sx={{ mb: "5px" }}>
                                                                        <Typography component={'span'} className={`${globalStyles.gblC171923} ${globalStyles.globalTextV16}`}>
                                                                            Confirm Password
                                                                        </Typography>
                                                                    </Grid>
                                                                    <Grid size={{ xs: 12 }}>
                                                                        <GlobalTextfield
                                                                            fullWidth
                                                                            placeholder='Enter Password'
                                                                            stylingClassName={`${globalStyles.globalFieldStyles}`}
                                                                            size='medium'
                                                                            autoComplete='off'
                                                                            type={showPassword ? 'text' : 'password'}
                                                                            onBlur={() => handleBlur("confirm_password")}
                                                                            error={Boolean(errors.confirm_password)}
                                                                            helperText={errors.confirm_password}
                                                                            value={forgotPasswordDetails.confirm_password}
                                                                            onChange={(e) => {
                                                                                setForgotPasswordDetails({
                                                                                    ...forgotPasswordDetails,
                                                                                    confirm_password: e.target.value
                                                                                })
                                                                            }}
                                                                            InputProps={{
                                                                                endAdornment: (
                                                                                    <InputAdornment position="start" sx={{ margin: '0px', borderLeft: "1px solid #CFD9E0" }}>
                                                                                        <IconButton
                                                                                            edge="start"
                                                                                            size='small'
                                                                                            onClick={() => { setShowConfirmPassword(!showConfirmPassword) }}
                                                                                            sx={{
                                                                                                margin: "0px 10px 0px 5px",
                                                                                            }}
                                                                                        >
                                                                                            {showConfirmPassword ?
                                                                                                <VisibilityOff
                                                                                                    sx={{ fontSize: "23px", color: "#718096" }}
                                                                                                /> :
                                                                                                <Visibility
                                                                                                    sx={{ fontSize: "23px", color: "#718096" }}
                                                                                                />
                                                                                            }
                                                                                        </IconButton>
                                                                                    </InputAdornment>
                                                                                ),
                                                                            }}
                                                                        />
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid size={{ xs: 12 }} sx={{ margin: "20px 0px" }}>
                                                        <GlobalButton
                                                            value={"Save & Reset"}
                                                            fullWidth
                                                            size='large'
                                                            className={`${styles.sendLinkBtn}`}
                                                            onClick={handleOtpSubmit}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        ) : (
                                            <>
                                                <Grid size={{ xs: 12 }} sx={{ margin: '25px 0px 40px' }}>
                                                    <Grid container>
                                                        <Grid size={{ xs: 12 }} sx={{ mb: '5px' }}>
                                                            <Typography component={'span'} className={`${globalStyles.gblC171923} ${globalStyles.globalTextV16}`}>
                                                                E-mail
                                                            </Typography>
                                                        </Grid>
                                                        <Grid size={{ xs: 12 }}>
                                                            <GlobalTextfield
                                                                fullWidth
                                                                placeholder='example@gmail.com'
                                                                stylingClassName={`${globalStyles.globalFieldStyles}`}
                                                                size='medium'
                                                                autoComplete='off'
                                                                value={email}
                                                                onChange={(e) => setEmail(e.target.value)}
                                                                onBlur={() => setEmailError(false)}
                                                                error={emailError}
                                                                helperText={emailError ? "Please Enter valid email" : ""}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </Grid>

                                                <Grid size={{ xs: 12 }} >
                                                    <GlobalButton
                                                        fullWidth
                                                        variant='contained'
                                                        value='Send Link'
                                                        size='large'
                                                        className={`${styles.sendLinkBtn}`}
                                                        onClick={handleSendOtp}
                                                    />
                                                </Grid>
                                            </>)
                                    }
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid size={{ xs: 5.8 }} className={`${loginStyles.signinBgWrapper}`}>
                            <Grid container>
                                <Grid className={`${loginStyles.signinElipsis}`}>
                                    <img src={ellipsis} alt="" />
                                </Grid>
                                <Grid size={{ xs: 10 }} className={`${loginStyles.signinBgSubWrapper}`}>
                                    <Grid container>
                                        <Grid size={{ xs: 12 }} sx={{ textAlign: 'center' }}>
                                            <img src={logo} style={{ width: "auto", height: "50px" }} alt="" />
                                        </Grid>
                                        <Grid className={`${loginStyles.signinIframeItem}`}>
                                            <iframe
                                                ref={iframeRef}
                                                src={videoUrl}
                                                frameBorder="0"
                                                allow="autoplay; fullscreen"
                                                allowFullScreen
                                                title="Vimeo Video"
                                                style={{
                                                    height: "100%",
                                                    width: "100%",
                                                }}
                                            />
                                        </Grid>
                                        <Grid className={`${loginStyles.signinIntroText}`}
                                            sx={{ width: "500px" }}
                                        >
                                            <Typography component={'span'} >
                                                Introducing myAdvisorCloud.
                                            </Typography>
                                        </Grid>
                                        <Grid className={`${loginStyles.signinIntroSubText}`}
                                            sx={{ width: "500px" }}
                                        >
                                            <Typography component={'span'}>
                                                The ONLY customized agent login portal in our industry offered by
                                                a brokerage that understands what innovation really means.
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}

export default ResetPasword