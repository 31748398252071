import { Button, SxProps, Theme } from '@mui/material'
import React from 'react'

interface buttonProps {
    value: string;
    className?: string;
    size?: 'small' | 'medium' | 'large';
    onClick?: () => void;
    variant?: 'text' | 'outlined' | 'contained';
    startIcon?: React.ReactNode;
    endIcon?: React.ReactNode;
    sx?: SxProps<Theme>;
    disabled?: boolean;
    fullWidth?: boolean
}

const GlobalButton = (props: buttonProps) => {
    return (
        <>
            <Button
                fullWidth={props.fullWidth}
                size={props.size}
                onClick={props.onClick}
                className={props.className}
                variant={props.variant}
                startIcon={props.startIcon}
                endIcon={props.endIcon}
                sx={props.sx}
                disabled={props.disabled}
            >
                {props.value}
            </Button>
        </>
    )
}


export default GlobalButton