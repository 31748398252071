import React, { Dispatch, SetStateAction, useContext, useEffect, useState } from 'react'
import styles from '../../Styles/ComponentStyles/GlobalComponentStyles/AppStores.module.scss'
import globalStyles from '../../Styles/global.module.scss'

import Grid from '@mui/material/Grid2';
import { Typography } from '@mui/material';
import GlobalButton from '../../Utils/Layout/GlobalButton/GlobalButton';
import Icons from '../../Utils/Layout/Icons/Icons';

import img1 from '../../Assets/Dashboard/clien-marketing.png'
import img2 from '../../Assets/Dashboard/brokersalliance.png'
import img3 from '../../Assets/Dashboard/lifebrain.png'
import img4 from '../../Assets/Dashboard/retirementbrain.png'
import img5 from '../../Assets/Dashboard/fa-portfolio-analysis.png'
import img6 from '../../Assets/Dashboard/advisor.png'
import img7 from '../../Assets/Dashboard/market-and-sales.png'
import img8 from '../../Assets/Dashboard/orion-advisor-solutions.png'
import img9 from '../../Assets/Dashboard/401k.png'
import img10 from '../../Assets/Dashboard/kplan.png'
import { DashboardContext, DashboardDetailPayload } from '../../Context/DashboardDetailContext';
import { baseURL } from '../../Utils/Constant/Constant';

interface appStoreDialogProps {
    accessDialog?: boolean;
    setFlagAccessDialog?: any;
    setShowAppName?: any;
}

const AppStores = React.memo(function AppStores(props: appStoreDialogProps) {

    const { setFlagAccessDialog, setShowAppName } = props

    const {
        dashboardDetails,
    } = useContext(DashboardContext) as DashboardDetailPayload

    const [sortedApps, setSortedApps] = useState<any[]>([]);

    useEffect(() => {
        if (dashboardDetails?.apps) {
            const sorted = [...dashboardDetails.apps].sort((a, b) => {
                if (a.appSection < b.appSection) return -1;
                if (a.appSection > b.appSection) return 1;
                return a.appOrder - b.appOrder;
            });
            setSortedApps(sorted);
        }
    }, [dashboardDetails]);

    // const appStoreData = [
    //     {
    //         headText: "Client Marketing and Correspondence",
    //         btnText: "Install",
    //         icon: <Icons iconName="downloadIcon" iconWidth="15px" iconHeight="15px" />,
    //         appImage: img1
    //     },
    //     {
    //         headText: "Brokers Alliance MAC",
    //         btnText: "Install",
    //         icon: <Icons iconName="downloadIcon" iconWidth="15px" iconHeight="15px" />,
    //         appImage: img2
    //     },
    //     {
    //         headText: "LifeBrain.io",
    //         btnText: "Install",
    //         icon: <Icons iconName="downloadIcon" iconWidth="15px" iconHeight="15px" />,
    //         appImage: img3
    //     },
    //     {
    //         headText: "Retirement Brain",
    //         btnText: "Install",
    //         icon: <Icons iconName="downloadIcon" iconWidth="15px" iconHeight="15px" />,
    //         appImage: img4
    //     },
    //     {
    //         headText: "Portfolio Analysis or Account intake",
    //         btnText: "Install",
    //         icon: <Icons iconName="downloadIcon" iconWidth="15px" iconHeight="15px" />,
    //         appImage: img5
    //     },
    //     {
    //         headText: "Licensing & Continuing Education",
    //         btnText: "Install",
    //         icon: <Icons iconName="downloadIcon" iconWidth="15px" iconHeight="15px" />,
    //         appImage: img10
    //     },
    //     {
    //         headText: "Revenue App",
    //         btnText: "Install",
    //         icon: <Icons iconName="downloadIcon" iconWidth="15px" iconHeight="15px" />,
    //         appImage: img6
    //     },
    //     {
    //         headText: "Marketing And Sales Resources",
    //         btnText: "Installed",
    //         appImage: img7
    //     },
    //     {
    //         headText: "Orion Advisor Solutions",
    //         btnText: "Installed",
    //         appImage: img8
    //     },
    //     {
    //         headText: "401 (K) Plan Fee Comparison",
    //         btnText: "Installed",
    //         appImage: img9
    //     },
    // ]



    return (
        <>
            <Grid container>
                <Grid size={{ xs: 12 }} sx={{ margin: "20px 0px 0px" }}>
                    <Grid container sx={{ gap: "15px", justifyContent: 'start' }}>
                        {sortedApps && sortedApps.map((ele: any) => {
                            const image = baseURL + "" + ele.image
                            return (
                                <>
                                    <Grid className={`${styles.appCardMainItem}`} key={ele.appName}>
                                        <Grid container sx={{ justifyContent: "space-between" }}>
                                            <Grid size={{ xs: 3 }}>
                                                <img
                                                    src={image ? image : ""}
                                                    alt="Comapny Image"
                                                    style={{ width: "100%", height: '100px', objectFit: 'fill', borderRadius: "10px" }}
                                                />
                                            </Grid>
                                            <Grid size={{ xs: 8.5 }} >
                                                <Grid container sx={{ height: "100%" }}>
                                                    <Grid size={{ xs: 12 }} className={`${styles.headTextItem}`}>
                                                        <Typography component={'span'} className={`${globalStyles.gblCWhite} ${globalStyles.globalTextV16}`}>
                                                            {ele.appName}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid size={{ xs: 12 }} >
                                                        <GlobalButton
                                                            value={ele.flagInstalled ? "Installed" : "Install"}
                                                            startIcon={ele.flagInstalled ? <Icons iconName="downloadIcon" iconWidth="15px" iconHeight="15px" /> : ""}
                                                            className={ele.flagInstalled ? `${styles.appBtnText}` : `${styles.appInstallBtn}`}
                                                            size='small'
                                                            onClick={() => {
                                                                setShowAppName(ele)
                                                                if (!ele.flagAccess) {
                                                                    setFlagAccessDialog(true)
                                                                }
                                                            }}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </>
                            )
                        })}

                    </Grid>
                </Grid>
            </Grid>
        </>
    )
})

export default AppStores