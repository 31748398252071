import React from 'react'

const GlobalSymbols = (props: any) => {

    const { symbol } = props

    return (
        <>
            {symbol == "astric" &&
                <span style={{ color: "red" }}>*</span>
            }
        </>
    )
}

export default GlobalSymbols