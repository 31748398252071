import { Autocomplete, AutocompleteProps, AutocompleteRenderOptionState, Chip, SxProps, TextField, TextFieldProps } from '@mui/material';
import React, { ReactNode } from 'react'
import styles from './GlobalAutoComplate.module.scss'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import CloseIcon from '@mui/icons-material/Close';

interface AutocompleteOption {
    label: string;
    value: string | number;
}

interface GlobalAutocompleteProps {
    options?: AutocompleteOption[];
    value?: any | null;
    onChange?: (
        event: React.SyntheticEvent,
        newValue: AutocompleteOption[] | null
    ) => void;
    label?: string;
    placeholder?: string;
    sx?: SxProps;
    textFieldProps?: TextFieldProps;
    renderInput?: (params: TextFieldProps) => ReactNode;
    size?: "small" | "medium" | undefined;
    className?: string;
    onInputChange?: any
    fullWidth?: boolean
    popupIcon?: ReactNode;
    multiple?: boolean;
    onBlur?: (event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>) => void;
    error?: boolean;
    helperText?: string;
}

const GlobalAutocomplate = (props: GlobalAutocompleteProps) => {

    const {
        options,
        value,
        onChange,
        label,
        placeholder,
        sx,
        textFieldProps,
        renderInput,
        size,
        className,
        onInputChange,
        fullWidth,
        multiple,
        onBlur,
        error, // Added error prop
        helperText,
        popupIcon = <KeyboardArrowDownIcon style={{ color: 'black' }} />,
        ...autocompleteProps
    } = props

    return (
        <>
            <Autocomplete
                fullWidth={fullWidth}
                options={options ? options : []}
                value={value}
                onChange={onChange}
                onInputChange={onInputChange}
                getOptionLabel={(option) => option.label}
                isOptionEqualToValue={(option, value) => option.value === value?.value}
                sx={sx}
                size={size}
                popupIcon={popupIcon}
                className={`${className ? className : styles.autoCompleteStyles}`}
                multiple={multiple}
                renderTags={(tagValue, getTagProps) =>
                    tagValue.map((option, index: any) => (
                        <Chip
                            label={option.label}
                            {...getTagProps({ index })}
                            className={styles.chip}
                            deleteIcon={<CloseIcon sx={{ fontSize: "17px !important", color: "black !important" }} />}
                        />
                    ))
                }
                renderInput={(params) =>
                    renderInput ? (
                        renderInput(params)
                    ) : (
                        <TextField
                            {...params}
                            label={label}
                            placeholder={placeholder}
                            {...textFieldProps}
                            onBlur={onBlur}
                            error={error}
                            helperText={helperText}
                        />
                    )
                }
                {...autocompleteProps}
            />
        </>
    )
}


export default GlobalAutocomplate