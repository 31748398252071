import React, { useContext, useEffect, useState } from 'react'
import styles from '../../../Styles/ComponentStyles/MainPages/dashboard.module.scss'
import globalStyles from '../../../Styles/global.module.scss'

import Grid from '@mui/material/Grid2';
import AppNavbar from '../../../Components/AppNavbar/AppNavbar';
import DashboardNavbar from '../DashboardNavbar/DashboardNavbar';
import { useNavigate } from 'react-router-dom';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Checkbox, FormControlLabel, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@mui/material';
import GlobalButton from '../../../Utils/Layout/GlobalButton/GlobalButton';
import { DashboardContext, DashboardDetailPayload } from '../../../Context/DashboardDetailContext';
import dayjs from "dayjs";
import Icons from '../../../Utils/Layout/Icons/Icons';
import { getRevenuePayloadData } from '../../../models/dashboardAxiosModels';
import { useSnackbar } from '../../../Utils/Layout/GlobalSnackbar/GlobalSnackbar';
import { GlobalUserContext, GlobalUserContextPayload } from '../../../Context/GlobalUserDetailContext';
import NorthIcon from '@mui/icons-material/North';

const DashboardRevenueApp = () => {

    const {
        dashboardRevenueDetails,
        setDashboardRevenueDetails,
        getRevenueData,
        dashboardRevenuePayload,
        setDashboardRevenuePayload,
        revenueGeneratePdf
    } = useContext(DashboardContext) as DashboardDetailPayload

    const {
        setFlagLoader,
    } = useContext(GlobalUserContext) as GlobalUserContextPayload

    const navigate = useNavigate()
    const { showSnackbar } = useSnackbar();

    const [rows, setRows] = useState<any>([
        {
            name: 'ADMS',
            email: 100,
            repcode: 100,
            date: '2024-08-01',
            group: "example",
            payoutAmount: "example",
            accounts: "example",
            accountsError: "example",
            billableMv: "example",
            advisorSplitAd: "example",
            exclusion: "example",
            discount: "example",
            cashShortages: "example",
            account401k: "example",
            accountBillableMv: "example",
            paymentAmount: "example",
            totalPayoutAmount: "example",

        },
        {
            name: 'ADMS',
            email: 100,
            repcode: 100,
            date: '2024-08-01',
            group: "example",
            payoutAmount: "example",
            accounts: "example",
            accountsError: "example",
            billableMv: "example",
            advisorSplitAd: "example",
            exclusion: "example",
            discount: "example",
            cashShortages: "example",
            account401k: "example",
            accountBillableMv: "example",
            paymentAmount: "example",
            totalPayoutAmount: "example",
        },
        {
            name: 'ADMS',
            email: 100,
            repcode: 100,
            date: '2024-08-01',
            group: "example",
            payoutAmount: "example",
            accounts: "example",
            accountsError: "example",
            billableMv: "example",
            advisorSplitAd: "example",
            exclusion: "example",
            discount: "example",
            cashShortages: "example",
            account401k: "example",
            accountBillableMv: "example",
            paymentAmount: "example",
            totalPayoutAmount: "example",
        },

    ]);

    const handleClick = () => {
        navigate('/')
    }

    const handleDateChange = (date: dayjs.Dayjs | null, type: "start" | "end") => {
        setDashboardRevenuePayload((prev: any) => {
            const months = [...prev.months];
            if (type === "start") {
                months[0] = date ? date : "";
            } else if (type === "end") {
                months[1] = date ? date : "";
            }
            return {
                ...prev,
                months: months.filter(Boolean),
            };
        });
    };

    const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setDashboardRevenuePayload((prev: any) => ({
            ...prev,
            flagShowAllAgentsData: e.target.checked,
        }));
    };

    const formatMonthYear = (date: any) => {
        if (!date) return ""; // Return empty if no date
        return date.format("MMMM-YYYY").toLowerCase(); // Convert to "april-2024" format
    };

    const handleRevenueData = async () => {
        const months = dashboardRevenuePayload?.months || [];
        const flagShowAllAgentsData = dashboardRevenuePayload?.flagShowAllAgentsData || false;
        const formattedMonths = months.map((month) => formatMonthYear(dayjs(month)));

        if (months.length !== 2) {
            showSnackbar("Please select both the start and end months.", "error")
            return;
        }
        setFlagLoader(true)
        const payload: getRevenuePayloadData = {
            months: formattedMonths,
            flagShowAllAgentsData,
        };

        try {
            const response = await getRevenueData(payload);
            if (response?.status) {
                console.log("response", response);
                if (response?.data) {
                    const revenueData = response?.data?.revenue_data || [];
                    setDashboardRevenueDetails({ revenue_data: revenueData });
                    setFlagLoader(false)
                }
            }
            else {
                const errorMessage = response?.data?.error?.message || 'Something Went Wrong!';
                showSnackbar(errorMessage, 'error');
                setFlagLoader(false)
            }
            setFlagLoader(false)
        } catch (error) {
            console.error("An error occurred while fetching revenue data:", error);
            showSnackbar('Something Went Wrong!', 'error');
            setFlagLoader(false)
        }
    };

    const handleRevenueGeneratePDF = async () => {
        const months = dashboardRevenuePayload?.months || [];
        const flagShowAllAgentsData = dashboardRevenuePayload?.flagShowAllAgentsData || false;
        const formattedMonths = months?.map((month) => formatMonthYear(dayjs(month)));

        if (months.length !== 2) {
            showSnackbar("Please select both the start and end months.", "error")
            return;
        }
        setFlagLoader(true)
        const payload: getRevenuePayloadData = {
            months: formattedMonths,
            flagShowAllAgentsData,
        };

        try {
            const response = await revenueGeneratePdf(payload);

            if (response?.status) {
                console.log("response", response);
                if (response?.data) {
                    setFlagLoader(false)
                }
                setFlagLoader(false)
            }
            else {
                const errorMessage = response?.data?.error?.message || 'Something Went Wrong!';
                showSnackbar(errorMessage, 'error');
                setFlagLoader(false)
            }
        } catch (error) {
            console.error("An error occurred while fetching revenue data:", error);
            showSnackbar('Something Went Wrong!', 'error');
            setFlagLoader(false)
        }
    }
    type SortDirection = "asc" | "desc" | null;
    const [sortConfig, setSortConfig] = useState<{ key: string; direction: SortDirection }>({
        key: "",
        direction: null,
    });

    const handleSort = (key: string) => {
        // Toggle sorting direction
        const direction = sortConfig.key === key && sortConfig.direction === "asc" ? "desc" : "asc";

        setSortConfig({ key, direction });

        // Sort the data
        if (dashboardRevenueDetails && dashboardRevenueDetails.revenue_data) {
            const sortedData = [...dashboardRevenueDetails.revenue_data].sort((a: any, b: any) => {
                if (a[key] < b[key]) {
                    return direction === "asc" ? -1 : 1;
                }
                if (a[key] > b[key]) {
                    return direction === "asc" ? 1 : -1;
                }
                return 0;
            });

            setDashboardRevenueDetails({ revenue_data: sortedData });
        }
    };

    const renderSortIcon = (key: string) => {
        if (sortConfig.key !== key) return null;
        return (
            <IconButton sx={{ padding: "2px" }}>
                <NorthIcon
                    sx={{
                        transform: sortConfig.direction === "asc" ? "rotate(0deg)" : "rotate(180deg)",
                        fontSize: "16px",
                        marginLeft: "5px",
                    }}
                />
            </IconButton>
        );
    };

    // LOGS:
    // console.log("dashboardRevenue", dashboardRevenueDetails);



    return (
        <>
            <AppNavbar navbarHeadingName={"Dashboard"} />
            <DashboardNavbar onClick={handleClick} headName={"Revenue App"} />
            <Grid container>
                <Grid size={{ xs: 12 }} sx={{ margin: '20px 0px 0px' }}>
                    <Grid container>
                        <Grid size={{ xs: 12 }}>
                            <Grid container>
                                <Grid size={{ xs: 8 }}>
                                    <Grid container sx={{ gap: "30px", alignItems: "center" }}>
                                        <Grid>
                                            <Grid container sx={{ gap: "10px", alignItems: 'center' }}>
                                                <Grid className={`${globalStyles.gblCWhite}`}>
                                                    Start Month
                                                </Grid>
                                                <Grid className={`${styles.startEndMonthItem}`}>
                                                    <DatePicker
                                                        value={dashboardRevenuePayload && dashboardRevenuePayload.months[0] ? dayjs(dashboardRevenuePayload.months[0], "MMMM-YYYY") : null}
                                                        onChange={(date) => handleDateChange(date, "start")}
                                                        className={`${globalStyles.globalDatepicker}`}
                                                        format="MMMM, YYYY"
                                                        views={["year", "month"]}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>

                                        <Grid>
                                            <Grid container sx={{ gap: "10px", alignItems: 'center' }}>
                                                <Grid className={`${globalStyles.gblCWhite}`}>
                                                    End Month
                                                </Grid>
                                                <Grid className={`${styles.startEndMonthItem}`}>
                                                    <DatePicker
                                                        value={dashboardRevenuePayload && dashboardRevenuePayload.months[1] ? dayjs(dashboardRevenuePayload.months[1], "MMMM-YYYY") : null}
                                                        onChange={(date) => handleDateChange(date, "end")}
                                                        className={`${globalStyles.globalDatepicker}`}
                                                        format="MMMM, YYYY"
                                                        views={["year", "month"]}
                                                        minDate={dashboardRevenuePayload && dashboardRevenuePayload?.months[0] ? dayjs(dashboardRevenuePayload?.months[0], "MMMM, YYYY") : undefined}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>

                                        <Grid>
                                            <GlobalButton
                                                value="Submit"
                                                size='medium'
                                                className={`${styles.raSubmitBtn}`}
                                                onClick={() => { handleRevenueData() }}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid size={{ xs: 4 }} sx={{ textAlign: 'end' }}>
                                    {dashboardRevenueDetails && dashboardRevenueDetails.revenue_data.length &&
                                        <GlobalButton
                                            value="Generate PDF"
                                            size='medium'
                                            className={`${styles.raSubmitBtn}`}
                                            onClick={() => {
                                                // handleRevenueGeneratePDF() 
                                            }}
                                        />
                                    }
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid size={{ xs: 12 }} sx={{ margin: "15px 0px 0px" }}>
                            <FormControlLabel
                                sx={{ color: "#ffffff" }}
                                label="Show All Agent’s data"
                                control={
                                    <Checkbox
                                        size="small"
                                        checked={dashboardRevenuePayload?.flagShowAllAgentsData}
                                        onChange={handleCheckboxChange}
                                        icon={<Icons iconName="checkbox" iconWidth="20px" iconHeight="20px" />}
                                        checkedIcon={<Icons iconName="checkedIcon" iconWidth="20px" iconHeight="20px" />}
                                        sx={{ padding: "0px 8px", color: "white", }}
                                    />
                                }
                            />
                        </Grid>

                    </Grid>
                </Grid>

                <Grid size={{ xs: 12 }} sx={{ margin: "15px 0px" }}>
                    <TableContainer className={`${styles.revTableContainer}`}>
                        <Table size='small' className={`${styles.revTable}`}>
                            <TableHead>
                                <TableRow>

                                    <TableCell className={`${styles.revTableSmallWidth} ${styles.revHeaderStyles}`} onClick={() => handleSort("user_full_name")}
                                        sx={{ borderTopLeftRadius: "5px", borderBottomLeftRadius: "5px" }}
                                    >
                                        <Typography
                                            className={`${globalStyles.globalTextV14}`}
                                            component='span'
                                            sx={{ display: "flex", cursor: "pointer" }}
                                        >
                                            NAME  {renderSortIcon("user_full_name")}
                                        </Typography>
                                    </TableCell>

                                    <TableCell className={`${styles.revTableSmallWidth} ${styles.revHeaderStyles}`} onClick={() => handleSort("email")}>
                                        <Typography
                                            className={`${globalStyles.globalTextV14}`}
                                            component='span'
                                            sx={{ display: "flex", cursor: "pointer" }}
                                        >
                                            EMAIL {renderSortIcon("email")}
                                        </Typography>
                                    </TableCell>

                                    <TableCell className={`${styles.revTableSmallWidth} ${styles.revHeaderStyles}`} onClick={() => handleSort("repcode")}>
                                        <Typography
                                            className={`${globalStyles.globalTextV14}`}
                                            component='span'
                                            sx={{ display: "flex", cursor: "pointer" }}
                                        >
                                            REPCODE {renderSortIcon("repcode")}
                                        </Typography>
                                    </TableCell>

                                    <TableCell className={`${styles.revTableSmallWidth} ${styles.revHeaderStyles}`} onClick={() => handleSort("date")}>
                                        <Typography
                                            className={`${globalStyles.globalTextV14}`}
                                            component='span'
                                            sx={{ display: "flex", cursor: "pointer" }}
                                        >
                                            DATE {renderSortIcon("date")}
                                        </Typography>
                                    </TableCell>

                                    <TableCell className={`${styles.revTableSmallWidth} ${styles.revHeaderStyles}`} onClick={() => handleSort("group_name")}>
                                        <Typography
                                            className={`${globalStyles.globalTextV14}`}
                                            component='span'
                                            sx={{ display: "flex", cursor: "pointer" }}
                                        >
                                            GROUP {renderSortIcon("group_name")}
                                        </Typography>
                                    </TableCell>

                                    <TableCell className={`${styles.revTableMediumWidth} ${styles.revHeaderStyles}`} onClick={() => handleSort("payout_amount")}>
                                        <Typography
                                            className={`${globalStyles.globalTextV14}`}
                                            component='span'
                                            sx={{ display: "flex", cursor: "pointer" }}
                                        >
                                            PAYOUT AMOUNT {renderSortIcon("payout_amount")}
                                        </Typography>
                                    </TableCell>

                                    <TableCell className={`${styles.revTableSmallWidth} ${styles.revHeaderStyles}`} onClick={() => handleSort("accounts")}>
                                        <Typography
                                            className={`${globalStyles.globalTextV14}`}
                                            component='span'
                                            sx={{ display: "flex", cursor: "pointer" }}
                                        >
                                            ACCOUNTS {renderSortIcon("accounts")}
                                        </Typography>
                                    </TableCell>

                                    <TableCell className={`${styles.revTableMediumWidth} ${styles.revHeaderStyles}`} onClick={() => handleSort("accounts_in_error")}>
                                        <Typography
                                            className={`${globalStyles.globalTextV14}`}
                                            component='span'
                                            sx={{ display: "flex", cursor: "pointer" }}
                                        >
                                            ACCOUNTS IN ERROR {renderSortIcon("accounts_in_error")}
                                        </Typography>
                                    </TableCell>

                                    <TableCell className={`${styles.revTableSmallWidth} ${styles.revHeaderStyles}`} onClick={() => handleSort("billable_mv")}>
                                        <Typography
                                            className={`${globalStyles.globalTextV14}`}
                                            component='span'
                                            sx={{ display: "flex", cursor: "pointer" }}
                                        >
                                            BILLABLE MV {renderSortIcon("billable_mv")}
                                        </Typography>
                                    </TableCell>

                                    <TableCell className={`${styles.revTableMediumWidth} ${styles.revHeaderStyles}`} onClick={() => handleSort("advisor_split_adjustment")}>
                                        <Typography
                                            className={`${globalStyles.globalTextV14}`}
                                            component='span'
                                            sx={{ display: "flex", cursor: "pointer" }}
                                        >
                                            ADVISOR SPLIT ADJUSTMENT {renderSortIcon("advisor_split_adjustment")}
                                        </Typography>
                                    </TableCell>

                                    <TableCell className={`${styles.revTableSmallWidth} ${styles.revHeaderStyles}`} onClick={() => handleSort("exclusions")}>
                                        <Typography
                                            className={`${globalStyles.globalTextV14}`}
                                            component='span'
                                            sx={{ display: "flex", cursor: "pointer" }}
                                        >
                                            EXCLUSIONS {renderSortIcon("exclusions")}
                                        </Typography>
                                    </TableCell>

                                    <TableCell className={`${styles.revTableSmallWidth} ${styles.revHeaderStyles}`} onClick={() => handleSort("discounts")}>
                                        <Typography
                                            className={`${globalStyles.globalTextV14}`}
                                            component='span'
                                            sx={{ display: "flex", cursor: "pointer" }}
                                        >
                                            DISCOUNTS {renderSortIcon("discounts")}
                                        </Typography>
                                    </TableCell>

                                    <TableCell className={`${styles.revTableMediumWidth} ${styles.revHeaderStyles}`} onClick={() => handleSort("cash_shortages")}>
                                        <Typography
                                            className={`${globalStyles.globalTextV14}`}
                                            component='span'
                                            sx={{ display: "flex", cursor: "pointer" }}
                                        >
                                            CASH SHORTAGES {renderSortIcon("cash_shortages")}
                                        </Typography>
                                    </TableCell>

                                    <TableCell className={`${styles.revTableMediumWidth} ${styles.revHeaderStyles}`} onClick={() => handleSort("401k_account")}>
                                        <Typography
                                            className={`${globalStyles.globalTextV14}`}
                                            component='span'
                                            sx={{ display: "flex", cursor: "pointer" }}
                                        >
                                            401(K) ACCOUNTS {renderSortIcon("401k_account")}
                                        </Typography>
                                    </TableCell>

                                    <TableCell className={`${styles.revTableMediumWidth} ${styles.revHeaderStyles}`} onClick={() => handleSort("401k_billable_mv")}>
                                        <Typography
                                            className={`${globalStyles.globalTextV14}`}
                                            component='span'
                                            sx={{ display: "flex", cursor: "pointer" }}
                                        >
                                            401(K) BILLABLE MV {renderSortIcon("401k_billable_mv")}
                                        </Typography>
                                    </TableCell>

                                    <TableCell className={`${styles.revTableMediumWidth} ${styles.revHeaderStyles}`} onClick={() => handleSort("401k_payout_amount")}>
                                        <Typography
                                            className={`${globalStyles.globalTextV14}`}
                                            component='span'
                                            sx={{ display: "flex", cursor: "pointer" }}
                                        >
                                            401(K) PAYMENT AMOUNT {renderSortIcon("401k_payout_amount")}
                                        </Typography>
                                    </TableCell>

                                    <TableCell
                                        className={`${styles.revTableMediumWidth} ${styles.revHeaderStyles}`}
                                        sx={{ borderTopRightRadius: "5px", borderBottomRightRadius: "5px" }}
                                        onClick={() => handleSort("total_payout_amount")}
                                    >
                                        <Typography
                                            className={`${globalStyles.globalTextV14}`}
                                            component='span'
                                            sx={{ display: "flex", cursor: "pointer" }}
                                        >
                                            TOTAL PAYOUT AMOUNT {renderSortIcon("total_payout_amount")}
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody className={`${styles.revTableTbody}`}>
                                {dashboardRevenueDetails && dashboardRevenueDetails.revenue_data.length ?
                                    dashboardRevenueDetails.revenue_data?.map((row: any, index: any) => {
                                        return (
                                            <>
                                                <TableRow key={index} className={`${styles.revTableRow}`}>
                                                    <TableCell
                                                        className={`${styles.revDataStyles} ${styles.revTableSmallWidth} ${globalStyles.globalTextV14}`}
                                                        sx={{
                                                            borderTopLeftRadius: "5px",
                                                            borderBottomLeftRadius: "5px"
                                                        }}
                                                    >
                                                        {row.user_full_name}
                                                    </TableCell>

                                                    <TableCell
                                                        className={`${styles.revDataStyles} ${styles.revTableSmallWidth} ${globalStyles.globalTextV14}`}
                                                    >
                                                        {row.email}
                                                    </TableCell>

                                                    <TableCell
                                                        className={`${styles.revDataStyles} ${styles.revTableSmallWidth} ${globalStyles.globalTextV14}`}
                                                    >
                                                        {row.repcode}
                                                    </TableCell>

                                                    <TableCell
                                                        className={`${styles.revDataStyles} ${styles.revTableSmallWidth} ${globalStyles.globalTextV14}`}
                                                    >
                                                        {row.date}
                                                    </TableCell>

                                                    <TableCell
                                                        className={`${styles.revDataStyles} ${styles.revTableSmallWidth} ${globalStyles.globalTextV14}`}
                                                    >
                                                        {row.group_name}
                                                    </TableCell>

                                                    <TableCell
                                                        className={`${styles.revDataStyles} ${styles.revTableMediumWidth} ${globalStyles.globalTextV14}`}
                                                    >
                                                        {row.payout_amount}
                                                    </TableCell>

                                                    <TableCell
                                                        className={`${styles.revDataStyles} ${styles.revTableSmallWidth} ${globalStyles.globalTextV14}`}
                                                    >
                                                        {row.accounts}
                                                    </TableCell>

                                                    <TableCell
                                                        className={`${styles.revDataStyles} ${styles.revTableMediumWidth} ${globalStyles.globalTextV14}`}
                                                    >
                                                        {row.accounts_in_error}
                                                    </TableCell>

                                                    <TableCell
                                                        className={`${styles.revDataStyles} ${styles.revTableSmallWidth} ${globalStyles.globalTextV14}`}
                                                    >
                                                        {row.billable_mv}
                                                    </TableCell>

                                                    <TableCell
                                                        className={`${styles.revDataStyles} ${styles.revTableMediumWidth} ${globalStyles.globalTextV14}`}
                                                    >
                                                        {row.advisor_split_adjustment}
                                                    </TableCell>

                                                    <TableCell
                                                        className={`${styles.revDataStyles} ${styles.revTableSmallWidth} ${globalStyles.globalTextV14}`}
                                                    >
                                                        {row.exclusions}
                                                    </TableCell>

                                                    <TableCell
                                                        className={`${styles.revDataStyles} ${styles.revTableSmallWidth} ${globalStyles.globalTextV14}`}
                                                    >
                                                        {row.discounts}
                                                    </TableCell>

                                                    <TableCell
                                                        className={`${styles.revDataStyles} ${styles.revTableMediumWidth} ${globalStyles.globalTextV14}`}
                                                    >
                                                        {row.cash_shortages}
                                                    </TableCell>

                                                    <TableCell
                                                        className={`${styles.revDataStyles} ${styles.revTableMediumWidth} ${globalStyles.globalTextV14}`}
                                                    >
                                                        {row['401k_account']}
                                                    </TableCell>

                                                    <TableCell
                                                        className={`${styles.revDataStyles} ${styles.revTableMediumWidth} ${globalStyles.globalTextV14}`}
                                                    >
                                                        {row['401k_billable_mv']}
                                                    </TableCell>

                                                    <TableCell
                                                        className={`${styles.revDataStyles} ${styles.revTableMediumWidth} ${globalStyles.globalTextV14}`}
                                                    >
                                                        {row['401k_payout_amount']}
                                                    </TableCell>

                                                    <TableCell
                                                        className={`${styles.revDataStyles} ${styles.revTableMediumWidth} ${globalStyles.globalTextV14}`}
                                                        sx={{
                                                            borderTopRightRadius: "5px",
                                                            borderBottomRightRadius: "5px",
                                                        }}
                                                    >
                                                        {row.total_payout_amount}
                                                    </TableCell>
                                                </TableRow>
                                            </>
                                        )
                                    }) : (
                                        <>
                                            <TableCell
                                                colSpan={17}
                                                sx={{ textAlign: 'center' }}
                                                className={`${styles.revDataStyles} ${styles.revTableMediumWidth} ${globalStyles.globalTextV14}`}
                                            >
                                                No Data Found
                                            </TableCell>
                                        </>
                                    )
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>
        </>
    )
}

export default DashboardRevenueApp