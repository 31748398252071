import React, { useState } from "react";
import { ChildrenProps } from "../models/componentModel";
import ArrowDropDownSharpIcon from '@mui/icons-material/ArrowDropDownSharp';
import ArrowDropUpSharpIcon from '@mui/icons-material/ArrowDropUpSharp';
import google from '../Assets/Dashboard/stock-google.png'
import apple from '../Assets/Dashboard/stock-apple.png'
import netflix from '../Assets/Dashboard/stock-netflix.png'
import { CommonResponseModel, CommonResponseSecondaryModel, MarketingPortfolioResponseModel, MarketingViewDetailResponseModel, TickerDetailsResponseModel, UserDashboardResponseModel } from "../models/axiosModels";
import { DashboardResponseModel, getRevenuePayloadData, RevenueDataResponseDataModel } from "../models/dashboardAxiosModels";
import agent from "../Services/api";

const DashboardContext = React.createContext<DashboardDetailPayload | undefined>(
    undefined
);

export interface DashboardDetailPayload {

    stockMarqueDetails: any;
    setStockMarqueDetails: React.Dispatch<React.SetStateAction<any>>

    // dashboardDetails: UserDashboardResponseModel | null;
    // setDashboardDetails: React.Dispatch<React.SetStateAction<UserDashboardResponseModel | null>>
    dashboardDetails: DashboardResponseModel | null;
    setDashboardDetails: React.Dispatch<React.SetStateAction<DashboardResponseModel | null>>
    getRevenueData: (payload: getRevenuePayloadData) => Promise<CommonResponseSecondaryModel<RevenueDataResponseDataModel>>;
    revenueGeneratePdf: (payload: getRevenuePayloadData) => Promise<CommonResponseSecondaryModel<RevenueDataResponseDataModel>>;

    dashboardRevenueDetails: RevenueDataResponseDataModel | null;
    setDashboardRevenueDetails: React.Dispatch<React.SetStateAction<RevenueDataResponseDataModel | null>>

    dashboardRevenuePayload: getRevenuePayloadData | null;
    setDashboardRevenuePayload: React.Dispatch<React.SetStateAction<getRevenuePayloadData | null>>

    marketingViewDetails: MarketingViewDetailResponseModel | null;
    setMarketingViewDetails: React.Dispatch<React.SetStateAction<MarketingViewDetailResponseModel | null>>

    tickerDetails: TickerDetailsResponseModel | null;
    setTickerDetails: React.Dispatch<React.SetStateAction<TickerDetailsResponseModel | null>>

    marketingPortfolioDetails: MarketingPortfolioResponseModel | null;
    setMarketingPortfolioDetails: React.Dispatch<React.SetStateAction<MarketingPortfolioResponseModel | null>>
}

const DashboardProvider = ({ children }: ChildrenProps) => {

    const [stockMarqueDetails, setStockMarqueDetails] = useState<any>([
        {
            img: google,
            stockText: "Alphabet Inc.",
            stockArrow: <ArrowDropDownSharpIcon sx={{ color: "green", }} />,
            stockPrice: "$224.23 (-2.73/ -1.20%)"
        },
        {
            img: apple,
            stockText: "Apple",
            stockArrow: <ArrowDropUpSharpIcon sx={{ color: "red" }} />,
            stockPrice: "$224.23 (-2.73/ -1.20%)"
        },
        {
            img: netflix,
            stockText: "Netflix",
            stockArrow: <ArrowDropUpSharpIcon sx={{ color: "green" }} />,
            stockPrice: "$224.23 (-2.73/ -1.20%)"
        },
        {
            img: google,
            stockText: "Alphabet Inc.",
            stockArrow: <ArrowDropDownSharpIcon sx={{ color: "green", }} />,
            stockPrice: "$224.23 (-2.73/ -1.20%)"
        },
        {
            img: apple,
            stockText: "Apple",
            stockArrow: <ArrowDropUpSharpIcon sx={{ color: "red" }} />,
            stockPrice: "$224.23 (-2.73/ -1.20%)"
        },
        {
            img: netflix,
            stockText: "Netflix",
            stockArrow: <ArrowDropUpSharpIcon sx={{ color: "green" }} />,
            stockPrice: "$224.23 (-2.73/ -1.20%)"
        },
        {
            img: google,
            stockText: "Alphabet Inc.",
            stockArrow: <ArrowDropDownSharpIcon sx={{ color: "green", }} />,
            stockPrice: "$224.23 (-2.73/ -1.20%)"
        },
        {
            img: apple,
            stockText: "Apple",
            stockArrow: <ArrowDropUpSharpIcon sx={{ color: "red" }} />,
            stockPrice: "$224.23 (-2.73/ -1.20%)"
        },
        {
            img: netflix,
            stockText: "Netflix",
            stockArrow: <ArrowDropUpSharpIcon sx={{ color: "green" }} />,
            stockPrice: "$224.23 (-2.73/ -1.20%)"
        },
    ])

    // const [dashboardDetails, setDashboardDetails] = useState<UserDashboardResponseModel | null>(null)
    const [dashboardDetails, setDashboardDetails] = useState<DashboardResponseModel | null>(null)
    const [dashboardRevenueDetails, setDashboardRevenueDetails] = useState<RevenueDataResponseDataModel | null>(null)
    const [dashboardRevenuePayload, setDashboardRevenuePayload] = useState<getRevenuePayloadData | null>({
        months: [],
        flagShowAllAgentsData: false,
    })

    const [marketingViewDetails, setMarketingViewDetails] = useState<MarketingViewDetailResponseModel | null>(null)
    const [tickerDetails, setTickerDetails] = useState<TickerDetailsResponseModel | null>(null)
    const [marketingPortfolioDetails, setMarketingPortfolioDetails] = useState<MarketingPortfolioResponseModel | null>(null)


    const getRevenueData = async (payload: getRevenuePayloadData) => {
        const response = await agent.getRevenueDataAPI.post(payload);
        return response
    }

    const revenueGeneratePdf = async (payload: getRevenuePayloadData) => {
        const response = await agent.revenueGeneratePdfAPI.post(payload);
        return response
    }


    const payload = {
        stockMarqueDetails,
        setStockMarqueDetails,

        dashboardDetails,
        setDashboardDetails,

        dashboardRevenuePayload,
        setDashboardRevenuePayload,

        marketingViewDetails,
        setMarketingViewDetails,

        tickerDetails,
        setTickerDetails,

        marketingPortfolioDetails,
        setMarketingPortfolioDetails,
        getRevenueData,

        dashboardRevenueDetails,
        setDashboardRevenueDetails,

        revenueGeneratePdf
    };

    return (
        <DashboardContext.Provider value={payload}>{children}</DashboardContext.Provider>
    );
};

export { DashboardContext, DashboardProvider };
