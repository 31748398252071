import logo from './logo.svg';
import './App.css';
import React, { Fragment } from 'react';
import Routes from './routes/routes.tsx';
import { BrowserRouter } from 'react-router-dom';
import { GlobalUserProvider } from './Context/GlobalUserDetailContext';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DashboardProvider } from './Context/DashboardDetailContext';
import { MemberProvider } from './Context/MemberDetailsContext';
import { SnackbarProvider } from './Utils/Layout/GlobalSnackbar/GlobalSnackbar';

function App() {
  return (
    <Fragment>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <SnackbarProvider>
          <GlobalUserProvider>
            <DashboardProvider>
              <MemberProvider>
                <BrowserRouter>
                  <Routes />
                </BrowserRouter>
              </MemberProvider>
            </DashboardProvider>
          </GlobalUserProvider>
        </SnackbarProvider>
      </LocalizationProvider>
    </Fragment>
  );
}

export default App;
