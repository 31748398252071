import React, { useContext, useEffect, useState } from 'react'
import styles from '../../Styles/ComponentStyles/GlobalComponentStyles/SideNavigationPanel.module.scss'
import globalStyles from '../../Styles/global.module.scss'
import {
    Box,
    Drawer,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Collapse,
    IconButton,
    Grid2,
    Avatar,
    Menu,
    MenuItem,
    Popover,
    useMediaQuery,
    Tooltip,
    Typography
} from '@mui/material';
import { GlobalUserContext, GlobalUserContextPayload } from '../../Context/GlobalUserDetailContext'
import Icons from '../../Utils/Layout/Icons/Icons'
import macLogo from '../../Assets/SideNavigationBar/mac-logo.png'
import { useLocation, useNavigate } from 'react-router-dom';
import drawerIcon from '../../Assets/SideNavigationBar/drawerIcon.svg'
import minMacLogo from '../../Assets/SideNavigationBar/minimizelogo.png'
import profile from '../../Assets/General/profile.png'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

interface MenuItem {
    name: string;
    icon: React.ReactNode;
    selectedIcon?: React.ReactNode;
    path: string;
    subItems?: MenuItem[];
    roles: string[];
}

const SideNavigationPanel = () => {

    const {
        drawerOpen,
        setDrawerOpen,
        currentSelectedTab,
        setCurrentSelectedTab,
        isAdminNavbarVisible,
        setIsAdminNavbarVisible,
        userRole,
        setUserRole,

        userProfileDetails
    } = useContext(GlobalUserContext) as GlobalUserContextPayload

    const navigate = useNavigate();
    const { pathname } = useLocation();

    const isProfileAdmin = userProfileDetails?.user?.flagSuperAdmin
    const isMobile = useMediaQuery('(max-width:600px)')

    useEffect(() => {
        // Logic to determine which tab should be highlighted based on the pathname
        if (pathname.toLowerCase() === '/dashboard') {
            setCurrentSelectedTab('Dashboard');
            setIsAdminNavbarVisible(true)
        }
        else if (pathname.toLowerCase() === '/dashboard/marketing-and-sales') {
            setCurrentSelectedTab('Dashboard');
            setIsAdminNavbarVisible(true)
        }
        else if (pathname.toLowerCase().includes('/member')) {
            setCurrentSelectedTab('Member');
        }
        else if (pathname.toLowerCase().includes('/member/user')) {
            setCurrentSelectedTab('Member');
        }
        else if (pathname.toLowerCase().includes('/revenueapp')) {
            setCurrentSelectedTab('Revenue App');
        }
        else if (pathname.toLowerCase() === '/leaderboard') {
            setCurrentSelectedTab('Leaderboard');
        }
        else {
            setCurrentSelectedTab('Dashboard');
            setIsAdminNavbarVisible(true)
        }
    }, [pathname]);

    const menuItems: MenuItem[] = [
        {
            name: 'Dashboard',
            icon: <Icons iconName='dashboard' iconWidth="22px" iconHeight="22px" />,
            selectedIcon: <Icons iconName='dashboard' iconColor="#000000" iconWidth="22px" iconHeight="22px" />,
            path: '/dashboard',
            roles: ['user', 'admin'], // Accessible by both User and Admin
        },
        {
            name: 'Member',
            icon: <Icons iconName='member' iconWidth="22px" iconHeight="22px" />,
            selectedIcon: <Icons iconName='member' iconColor="#000000" iconWidth="22px" iconHeight="22px" />,
            path: '/member',
            roles: ['admin'], // Only accessible by Admin
        },
        {
            name: 'Revenue App',
            icon: <Icons iconName='moneyIcon' iconWidth="22px" iconHeight="22px" />,
            selectedIcon: <Icons iconName='moneyIcon' iconColor="#000000" iconWidth="22px" iconHeight="22px" />,
            path: '/revenueapp',
            roles: ['admin'], // Only accessible by Admin
        },
        {
            name: 'Leaderboard',
            icon: <Icons iconName='leaderboard' iconWidth="22px" iconHeight="22px" />,
            selectedIcon: <Icons iconName='leaderboard' iconColor="#000000" iconWidth="22px" iconHeight="22px" />,
            path: '/leaderboard',
            roles: ['admin'], // Only accessible by Admin
        }
    ];

    const handleNavigate = (path: any) => {
        setCurrentSelectedTab(path.split('/').pop()); // Extracts the last part of the path to use as the key
        navigate(path);
    };

    return (
        <>
            <Grid2 container className={`${styles.navigationMainContainer} ${globalStyles.fullHeightStyle}  `}>
                <Grid2 className={`${globalStyles.fullHeightStyle}`}>
                    <Drawer
                        variant="permanent"
                        className={`${globalStyles.fullHeightStyle}`}
                        sx={{
                            display: { xs: 'none', sm: 'block' },
                            '& .MuiDrawer-paper': {
                                background: "none !important",
                                maxWidth: `${drawerOpen ? "252px" : "80px"}`,
                                position: "relative"
                                // top: isAdminNavbarVisible ? "60px" : ""
                            }
                        }}
                        open
                    >
                        <Grid2 container className={`${styles.navigationWrapper} ${globalStyles.fullHeightStyle}`}>
                            <Grid2 className={`${globalStyles.fullHeightStyle}`} sx={{ width: "100%" }}>
                                <Grid2 container className={`${globalStyles.fullHeightStyle}`}>
                                    <Grid2 size={{ xs: 12 }} className={`${globalStyles.fullHeightStyle}`}>
                                        <Grid2 container className={`${globalStyles.fullHeightStyle}`}>
                                            <Grid2 size={{ xs: 12 }} className={`${drawerOpen ? globalStyles.sidenavigationHeightsCloseDrawer : globalStyles.sidenavigationHeights}`}>
                                                <Grid2 container >
                                                    <Grid2 size={{ xs: 12 }}>
                                                        <Box
                                                            sx={{
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                                gap: '12px',
                                                                justifyContent: 'flex-start'
                                                            }}
                                                        >

                                                            {drawerOpen ?
                                                                <Grid2
                                                                    size={{ xs: 12 }}
                                                                    component="img"
                                                                    className={styles.logo}
                                                                    alt="Logo"
                                                                    src={macLogo}
                                                                /> :
                                                                <Grid2
                                                                    size={{ xs: 12 }}
                                                                    component="img"
                                                                    className={styles.logo}
                                                                    alt="Logo"
                                                                    src={minMacLogo}
                                                                />
                                                            }

                                                            {drawerOpen ? (
                                                                <IconButton
                                                                    sx={{ padding: '0px' }}
                                                                    onClick={() => setDrawerOpen(false)}
                                                                    className={styles.drawerCloseIcon}
                                                                >
                                                                    <img src={drawerIcon} alt="Close Drawer" />
                                                                </IconButton>
                                                            ) : (
                                                                <IconButton
                                                                    sx={{ padding: '0px' }}
                                                                    onClick={() => setDrawerOpen(true)}
                                                                    className={styles.drawerOpenIcon}
                                                                >
                                                                    <img src={drawerIcon} alt="Open Drawer" />
                                                                </IconButton>
                                                            )}
                                                        </Box>
                                                    </Grid2>

                                                    <Grid2 size={{ xs: 12 }} className={`${styles.sideNavigationMainItem}`}>
                                                        <List className={`${styles.navigationList}`}>
                                                            {menuItems.filter((item) => item?.roles?.includes(isProfileAdmin ? "admin" : "user"))?.map((item) => (
                                                                <ListItem
                                                                    key={item.name}
                                                                    onClick={() => handleNavigate(item.path)}
                                                                    className={`${styles.sideNavigationList}`}
                                                                    sx={{
                                                                        backgroundColor: currentSelectedTab === item.name ? "#ffffff" : "transparent",
                                                                        color: currentSelectedTab === item.name ? "#000000" : "#ffffff",
                                                                    }}
                                                                >
                                                                    <Grid2 size={{ xs: 12 }}>
                                                                        <Grid2 container sx={{ gap: drawerOpen ? "15px" : "0", justifyContent: 'center' }}>
                                                                            <Grid2 className={`${styles.sideNavigationNameIcon}`}>
                                                                                <div>
                                                                                    {currentSelectedTab === item.name ? item.selectedIcon : item.icon}
                                                                                </div>
                                                                            </Grid2>
                                                                            <Grid2 className={`${styles.sideNavigationName}`}>
                                                                                {drawerOpen ?
                                                                                    (
                                                                                        <Typography component='span'>
                                                                                            {item.name}
                                                                                        </Typography>
                                                                                    ) : (<></>)
                                                                                }
                                                                            </Grid2>
                                                                        </Grid2>
                                                                    </Grid2>
                                                                </ListItem>
                                                            ))}
                                                        </List>
                                                    </Grid2>
                                                </Grid2>
                                            </Grid2>

                                            {isProfileAdmin &&
                                                <Grid2 size={{ xs: 12 }} sx={{ padding: drawerOpen ? '0px 10px' : '0px 5px' }}>
                                                    <Grid2 className={`${globalStyles.sideNavbarProfileItem}`}>
                                                        <Grid2 container className={`${globalStyles.sideNavProfileContainer}`}>
                                                            <Grid2 size={{ xs: drawerOpen ? 2 : 7.5 }} className={`${globalStyles.sideNavImg}`}>
                                                                <Avatar
                                                                    src={profile}
                                                                    sx={{
                                                                        width: drawerOpen ? "100%" : "30px",
                                                                        height: drawerOpen ? "100%" : "30px"
                                                                    }}
                                                                />
                                                            </Grid2>
                                                            {!drawerOpen ? <></> : <>
                                                                <Grid2 size={{ xs: 7.5 }} className={`${globalStyles.sideNavAdminItem}`}>
                                                                    <Grid2 container>
                                                                        <Grid2 size={{ xs: 12 }}>
                                                                            <Typography component={'span'} className={`${globalStyles.globalTextV14}`}>
                                                                                Admin
                                                                            </Typography>
                                                                        </Grid2>
                                                                        <Grid2 size={{ xs: 12 }}>
                                                                            <Typography component={'span'} className={`${globalStyles.globalTextV14}`}>
                                                                                Admin@gmail.com
                                                                            </Typography>
                                                                        </Grid2>
                                                                    </Grid2>
                                                                </Grid2>
                                                            </>}

                                                            <Grid2 size={{ xs: drawerOpen ? 2 : 3.5 }} className={`${globalStyles.profileArrowItem}`}>
                                                                <IconButton sx={{ padding: "0px" }}>
                                                                    <ArrowForwardIosIcon sx={{ fontSize: "18px", color: 'black' }} />
                                                                </IconButton>
                                                            </Grid2>
                                                        </Grid2>
                                                    </Grid2>
                                                </Grid2>
                                            }
                                        </Grid2>
                                    </Grid2>
                                </Grid2>
                            </Grid2>
                        </Grid2>
                    </Drawer>
                </Grid2>
            </Grid2>
        </>
    )
}


export default SideNavigationPanel