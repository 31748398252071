import React from 'react'
import CircularProgress from '@mui/material/CircularProgress';

const GlobalLoader = () => {

    const centerDivStyle = {
        position: "fixed",
        top: 0,
        left: 0,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        minHeight: "100vh",
        flexDirection: "column",
        zIndex: 99999999,
        background: "rgba(0,0,0,0.1)"
    }

    const loaderStyle = {
        background: "transparent",
        color: "#fff"
    }

    return (

        <div
            className="center_div"
            style={{
                position: "fixed",
                top: 0,
                left: 0,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                minHeight: "100vh",
                flexDirection: "column",
                zIndex: 99999999,
                background: "rgba(0,0,0,0.1)"
            }}>
            <CircularProgress size="45px" id="loader" style={loaderStyle} />
        </div>

    )
}

export default GlobalLoader;